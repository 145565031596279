import { apiSwrRequest } from '@services/http/client';
import AppUser from '@components/bridge/bridge/immutable/app-user';

export interface Template {
    node: {
        id: string;
    };
}

export interface QueryAppUser extends AppUser {
    id: string;
    registeredAt: string;
    registererId: string;
    organizationId: string;
    favouriteTemplates: {
        edges: Template[];
        totalCount: number;
    };
    organization: {
        id: string;
        motherId: string;
        libraryIds: string[];
        currency: string;
        name: string;
        advancedMode: boolean;
        company: boolean;
        explodeAt: string;
        memberIds: string[];
        taxable: boolean;
        hasKnownAtLeastOneDoneVideo: boolean;
        botIds: string[];
        thirdCustomerId: string;
        currentlyHasAtLeastOneOpenInvoice: boolean;
        currentlyHasAtLeastOneProcessingPaymentIntent: boolean;
        bots: {
            edges: {
                node: {
                    id: string;
                    hosted: boolean;
                    authKey: string;
                    materials: {
                        serverId: string;
                    };
                };
            }[];
        };
        licenses: {
            id: string;
            cloudy: boolean;
            periodic: boolean;
        }[];
        directors: {
            totalCount: number;
        };
        members: {
            totalCount: number;
        };
        videoIds: string[];
        whitelabel: {
            logo: {
                src: string;
            };
            name: string;
            domain: {
                name: string;
                securityCert: string;
                securityKey: string;
            };
            colorScheme: {
                first: string;
                second: string;
            };
            metaBag: {
                title: string;
                description: string;
                thumbnail: {
                    src: string;
                };
            };
        };
        workspaceIds: string[];
        workspaces: {
            totalCount: number;
            edges: {
                node: {
                    id: string;
                    name: string;
                    logo: {
                        src: string;
                    };
                    badgedUserIds: string[];
                };
            }[];
        };
        sponsorCode: string;
        sponsoredOrganizationsHavingPaidAtLeastOnceInThePast: {
            totalCount: number;
            edges: {
                node: {
                    referent: {
                        email: string;
                    };
                };
            };
        };
    };
    account: {
        activated: boolean;
        email: string;
    };
    canI: {
        getUiModuleTo: {
            generateVideoViaAiCompletion: boolean;
            startHomeStagingImageGeneration: boolean;
            startImageToVideoGeneration: {
                itself: boolean;
                detectMyIntentToProceed: boolean;
                _remainingGenerationsForThisMonth: number;
                _generationsLimitPerMonth: number;
            };
        };
    };
}

declare interface Payload {
    getUser: QueryAppUser;
}

class UserService {
    static getUser = async (query: any): Promise<Payload['getUser']> => {
        const user = await apiSwrRequest(query, 'bridgeUser');
        return user;
    };
}

export default UserService;
