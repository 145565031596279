import styled from '@emotion/styled';
import { motion } from 'framer-motion';
export const BtnContainer = styled(motion.div)`
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 0.4rem;
    max-height: 40px;
    cursor: pointer;

    svg {
        inline-size: 1.5rem;
        overflow: visible;
    }
`;
export const IconContainer = styled('div')`
    color: white;
`;
export const SparkleContainer = styled(motion.div)`
    position: absolute;
    overflow: hidden;
    height: 100%;
    width: 100%;
    translate: -50% -50%;
    left: 50%;
    top: 50%;
    mask: radial-gradient(white, transparent 65%);
    z-index: -1;

    svg {
        position: absolute;
        fill: white;
        aspect-ratio: 1;
        z-index: -1;
    }
`;
export const HighlightContainer = styled(motion.div)`
    position: absolute;
    border-radius: inherit;
    overflow: hidden;
    inset: 0;
    padding-top: 2px;
    -webkit-mask-composite: xor;
    mask-composite: xor;
    -webkit-mask: linear-gradient(#fff 0 0) content, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content, linear-gradient(#fff 0 0);

    .highlight {
        width: 200%;
        background: conic-gradient(from 0deg, transparent 0 330deg, white 360deg);
        -webkit-mask-image: linear-gradient(white 5%, transparent 20%);
        mask-image: linear-gradient(white 5%, transparent 20%);
        position: absolute;
        border-radius: inherit;
        z-index: -1;
        top: 0;
        left: 50%;
        translate: -50% -10%;
        aspect-ratio: 1;
    }

    svg {
        inline-size: 1.6rem;
        overflow: visible;
    }
`;