enum CameraMoveEnum {
    AUTO = 'AUTO',
    DOLLY_IN = 'DOLLY_IN',
    DOLLY_OUT = 'DOLLY_OUT',
    CRANE_UP = 'TILT_UP',
    CRANE_DOWN = 'CRANE_DOWN',
    PAN_LEFT = 'PAN_LEFT',
    PAN_RIGHT = 'PAN_RIGHT'
}

export default CameraMoveEnum;
