import { HomeStagingFormData } from '@components/office/front-office/home/home-staging/dialog/types/HomeStagingFormData';
import { apiRequest, axios } from '@services/http/client';
import { HomeStagingRoom } from '@components/office/front-office/home/home-staging/dialog/types/HomeStagingRoom';
import HomeStagingTreatmentEnum from '@components/office/front-office/home/home-staging/dialog/enums/HomeStagingTreatmentEnum';
import { HomeStagingPostBody } from '@components/office/front-office/home/home-staging/dialog/types/HomeStagingPostBody';
import { HomeStagingStepConfiguration } from '@components/office/front-office/home/home-staging/dialog/types/HomeStagingStepConfiguration';
import HomeStagingKindEnum from '@components/office/front-office/home/home-staging/dialog/enums/HomeStagingKindEnum';
const getStepperConfiguration = (): HomeStagingStepConfiguration[] => [{
  index: 0,
  title: tr('home_staging.steps.upload.stepper_config.title')
}, {
  index: 1,
  title: tr('home_staging.steps.definition.stepper_config.title')
}, {
  index: 2,
  title: tr('home_staging.steps.options.stepper_config.title')
}, {
  index: 3,
  title: tr('home_staging.steps.generate.stepper_config.title')
}];
const getTreatmentTypes = () => [{
  name: 'REDECORATE',
  label: tr('home_staging.treatments.redecorate')
}, {
  name: 'FURNISH',
  label: tr('home_staging.treatments.furnish')
}, {
  name: 'ENHANCE',
  label: tr('home_staging.treatments.enhance')
}];
const getInitialFormData = (): HomeStagingFormData => {
  return {
    file: null,
    room: null,
    treatment: null,
    style: null,
    outRoom: null,
    blueSkyMode: false,
    strength: 0.6
  };
};
const isValideStep = (selectedStepNumber: number, formData: HomeStagingFormData) => {
  if (selectedStepNumber === 0 && formData.file) return true;
  if (selectedStepNumber === 1) {
    const isStyleValid: boolean = [(HomeStagingTreatmentEnum.REDECORATE as string), (HomeStagingTreatmentEnum.FURNISH as string)].includes(formData.treatment) ? Boolean(formData.style) : true;
    const isFillTypeValid: boolean = formData.treatment === HomeStagingTreatmentEnum.FURNISH && formData.room?.kind === (HomeStagingKindEnum.INSIDE as string) ? Boolean(formData.outRoom) : true;
    return formData.room && formData.treatment && isStyleValid && isFillTypeValid;
  }
  return !!(selectedStepNumber === 2 && ((formData.treatment === 'REDECORATE' || formData.treatment === 'FURNISH') && formData.strength || formData.treatment === 'ENHANCE'));
};
const getStyles = (room: HomeStagingRoom) => {
  return apiRequest(`
                query getHomeStagingStyles {
                    specs {
                        homeStaging {
                            styles(forType: "${room.name}") {
                                name
                                label
                                exampleImageUrl
                                kind
                            }
                        }
                    }
                }
            `).then(data => {
    return data?.data?.specs?.homeStaging?.styles;
  });
};
const getRooms = () => {
  return apiRequest(`
                query getHomeStagingTypes {
                    specs {
                        homeStaging {
                            types {
                                name
                                label
                                exampleImageUrl
                                kind
                                isAlsoFillType
                            }
                        }
                    }
                }
            `).then(data => {
    return data?.data?.specs?.homeStaging?.types;
  });
};
const getOutRooms = (room: HomeStagingRoom) => {
  return apiRequest(`
                query getHomeStagingFillTypes {
                    specs {
                        homeStaging {
                            fillTypes (forType: "${room.name}") {
                                name
                                label
                                exampleImageUrl
                                kind
                            }
                        }
                    }
                }
            `).then(data => {
    return data?.data?.specs?.homeStaging?.fillTypes;
  });
};
const getGeneration = generationId => {
  if (!generationId) return Promise.resolve(null);
  return apiRequest(`
                query getHomeStagingPreGenerations {
                    me {
                        profile {
                            preGenerations {
                                homeStaging(id: "${generationId}") {
                                    id
                                    startedAt
                                    doneAt
                                    done
                                    inputFile {
                                        src
                                    }
                                    outputFile {
                                        src
                                    }
                                }
                            }
                        }
                    }
                }
            `).then(data => {
    return data?.data?.me?.profile?.preGenerations?.homeStaging?.[0];
  });
};
const getKeeps = (room: HomeStagingRoom) => {
  return apiRequest(`
                query getHomeStagingKeeps {
                    specs {
                        homeStaging {
                            keptElements(forType: "${room.name}") {
                                name
                                label
                                kind
                            }
                        }
                    }
                }
            `).then(data => {
    return data?.data?.specs?.homeStaging?.keptElements;
  });
};
const getBodyFromFormData = (formData: HomeStagingFormData): HomeStagingPostBody => {
  return {
    file: formData.file.id,
    op: formData.treatment,
    prompt: {
      type: formData?.room?.name,
      style: formData?.style?.name,
      fill_type: formData?.outRoom?.name,
      ...(Array.isArray(formData?.keptElements) && formData.keptElements.length ? {
        kept_elements: formData.keptElements
      } : {})
    },
    sky_included: formData.blueSkyMode,
    ...((formData.treatment === 'REDECORATE' || formData.treatment === 'FURNISH') && formData.strength !== undefined && {
      strength: formData.strength
    })
  };
};
const postGenerateImageWithPolling = (formData: HomeStagingFormData, setIsLoading, setGenerationSrc) => {
  setIsLoading(true);
  setGenerationSrc(null);
  const options = {
    method: 'POST',
    url: gen('write/me/generate-home-staging-image'),
    data: getBodyFromFormData(formData),
    headers: {
      'Content-Type': 'application/json'
    },
    underWhitelabel: !window.areWeUnderDanimHost()
  };
  return axios(options).then(response => {
    if (response?.success) {
      const generationId = response?.data?.generation_id;
      if (generationId) {
        let test: number = 0;
        const polling = setInterval(() => {
          test++;
          if (test > 60) {
            setIsLoading(false);
            clearInterval(polling);
            return;
          }
          HomeStaging.getGeneration(generationId).then(response => {
            if (response?.done) {
              setIsLoading(false);
              setGenerationSrc(response?.outputFile?.src);
              clearInterval(polling);
            }
          });
        }, 5000);
      } else {
        addFlash({
          type: 'error',
          message: "Une erreur est survenue lors de la génération de l'image"
        });
      }
    }
    return false;
  });
};
const downloadImage = async (src: string) => {
  if (!src) return;
  const partsOfUrl = src.split('/');
  const fileName = partsOfUrl[partsOfUrl.length - 1];
  const response = await fetch(src);
  const blob = await response.blob();
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(blobUrl);
};
const HomeStaging = {
  getStepperConfiguration,
  getTreatmentTypes,
  getInitialFormData,
  isValideStep,
  getStyles,
  getRooms,
  getOutRooms,
  getGeneration,
  getKeeps,
  downloadImage,
  postGenerateImageWithPolling
};
export default HomeStaging;