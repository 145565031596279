import { Variants } from 'framer-motion';
import { alpha, lighten } from '@mui/system/colorManipulator';
const RANDOM = (min: number, max: number): number => Math.floor(Math.random() * (max - min + 1) + min);
export const buttonAnim: Variants = {
  init: theme => ({
    scale: 1,
    boxShadow: '0 0 0rem 0rem rgba(66, 18, 161, 0)',
    outline: '3px solid rgba(129, 75, 255, 0)',
    // background: 'radial-gradient(70% 90% at 50% 100%, rgb(31, 30, 31) 0%, rgb(31, 30, 31) 100%)',
    background: `radial-gradient(70% 90% at 50% 100%, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 100%)`,
    filter: 'hue-rotate(8deg)',
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 0.3
    }
  }),
  anim: theme => ({
    scale: 1.05,
    outline: `3px solid ${alpha(theme.palette.primary.main, 0.2)}`,
    background: `radial-gradient(70% 90% at 50% 100%, ${lighten(theme.palette.primary.main, 0.1)} 0%, ${lighten(theme.palette.primary.main, 0.1)} 100%)`,
    transition: {
      type: 'spring',
      bounce: 0.25,
      duration: 0.5
    }
  }),
  tap: {
    scale: 1
  }
};
export const disabledAnim: Variants = {
  init: theme => ({
    scale: 1,
    boxShadow: '0 0 0rem 0rem rgba(66, 18, 161, 0)',
    outline: '3px solid rgba(129, 75, 255, 0)',
    background: 'rgb(120, 120, 120)',
    // background: `radial-gradient(70% 90% at 50% 100%, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 100%)`,
    filter: 'hue-rotate(8deg)',
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 0.3
    }
  }),
  anim: theme => ({
    scale: 1.05,
    outline: `3px solid ${alpha(theme.palette.primary.main, 0.2)}`,
    transition: {
      type: 'spring',
      bounce: 0.25,
      duration: 0.5
    }
  }),
  tap: {
    scale: 1
  }
};
export const labelAnim: Variants = {
  init: theme => ({
    marginLeft: '0.4rem',
    background: theme.palette.mode === 'dark' ? 'rgb(31, 30, 31)' : 'rgb(255, 255, 255)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 0.5
    }
  }),
  anim: {
    // background: 'linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 100%)',
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 1
    }
  }
};
export const svgAnim: Variants = {
  init: {},
  anim: {
    transition: {
      staggerChildren: 0.2,
      delayChildren: 1
    }
  }
};
export const pathAnim: Variants = {
  init: (i: number) => ({
    fill: 'rgb(255, 255, 255)',
    opacity: 1,
    transition: {
      duration: 0.6
    }
  }),
  anim: (i: number) => ({
    opacity: [null, 0.5, 1, i === 1 ? 1 : (3 - i + 1) / 4],
    scale: [null, 0.5, 1.3, 1],
    transition: {
      duration: 0.6,
      repeatDelay: 2.2,
      repeat: Infinity
    }
  })
};
export const highlightContainerAnim: Variants = {
  init: {
    scaleY: 1,
    scaleX: 1,
    transition: {
      ease: 'linear',
      duration: 0.001
    }
  },
  anim: {
    scaleY: [null, -1],
    scaleX: [null, -1],
    transition: {
      ease: 'linear',
      duration: 0.001,
      delay: 1.3,
      repeatDelay: 1.3,
      repeatType: 'reverse',
      repeat: Infinity
    }
  }
};
export const highlightAnim: Variants = {
  init: {
    rotateZ: -65,
    transition: {
      ease: 'linear',
      duration: 0.001
    }
  },
  anim: {
    rotateZ: [null, 65],
    transition: {
      ease: [0.5, 1, 0.89, 1],
      duration: 1.3,
      repeat: Infinity
    }
  }
};
export const sparkleAnim: Variants = {
  init: (i: number) => ({
    rotateZ: 0,
    opacity: 0,
    scale: 0,
    top: `calc(${RANDOM(3, 9)} * 10%)`,
    left: `calc(${RANDOM(3, 9)} * 10%)`,
    width: `calc(${RANDOM(3, 9) / 10} * 14px)`,
    transformOrigin: `${Math.random() > 0.5 ? RANDOM(30, 80) * -10 : RANDOM(30, 80) * 10}% ${Math.random() > 0.5 ? RANDOM(30, 80) * -10 : RANDOM(30, 80) * 10}%`,
    transition: {
      duration: 0.5
    }
  }),
  anim: (i: number) => ({
    scale: 1,
    rotateZ: i % 2 === 0 ? [0, 360] : [-360, 0],
    opacity: RANDOM(5, 8) / 10,
    transition: {
      opacity: {
        duration: 1,
        ease: 'anticipate'
      },
      scale: {
        duration: 2,
        ease: 'anticipate'
      },
      ease: 'linear',
      delay: RANDOM(1, 10) * -1,
      duration: RANDOM(10, 25),
      repeat: Infinity
    }
  })
};