import React from 'react';
import { HomeStagingFormData } from '@components/office/front-office/home/home-staging/dialog/types/HomeStagingFormData';
import { CircularProgress, useMediaQuery } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import TooltipBox from '@components/bridge/bridge/TooltipBox';
import useTheme from '@mui/system/useTheme';
type Props = {
  formData: HomeStagingFormData;
  isLoading: boolean;
  generationSrc: string;
};
const HomeStagingGenerateStep = (props: Props) => {
  const {
    formData,
    isLoading,
    generationSrc
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '10px'
  }}>
            {isLoading && <div style={{
      paddingLeft: '30px',
      paddingRight: '30px',
      fontStyle: 'italic'
    }}>
                    {tr('home_staging.steps.generate.summary_sentence')}
                </div>}
            <div style={{
      display: 'flex',
      gap: '10px',
      maxHeight: !isMobile ? '400px' : undefined,
      flexDirection: !isMobile ? 'row' : 'column'
    }}>
                <img src={formData.file.url} alt='' style={{
        outline: '1px solid grey',
        flexGrow: 1,
        width: !isMobile ? '250px' : undefined,
        maxHeight: '400px',
        objectFit: 'contain',
        maxWidth: isMobile ? '400px' : undefined
      }} />
                {isLoading && <div style={{
        outline: '1px solid grey',
        flexGrow: 1,
        width: !isMobile ? '250px' : undefined,
        maxHeight: '400px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: isMobile ? '200px' : undefined,
        maxWidth: isMobile ? '400px' : undefined
      }}>
                        <CircularProgress />
                    </div>}
                {!isLoading && <img src={generationSrc} alt='' height='auto' style={{
        outline: '1px solid grey',
        flexGrow: 1,
        width: !isMobile ? '250px' : undefined,
        maxHeight: '400px',
        objectFit: 'contain',
        maxWidth: isMobile ? '400px' : undefined
      }} />}
            </div>
            <div style={{
      display: 'flex',
      justifyContent: 'flex-end'
    }}>
                <TooltipBox caption={tr('home_staging.steps.generate.suggestion')} style={{
        top: '50%',
        right: 'calc(100% + 0.4rem)',
        transform: 'translateY(-50%)'
      }}>
                    <InfoIcon />
                </TooltipBox>
            </div>
        </div>;
};
export default HomeStagingGenerateStep;