import React from 'react';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { ImageToVideoStepConfiguration } from '@components/office/front-office/home/image-to-video/dialog/types/ImageToVideoStepConfiguration';
import { useMediaQuery } from '@mui/material';
import useTheme from '@mui/system/useTheme';
type Props = {
  stepperConfiguration: ImageToVideoStepConfiguration[];
  selectedStepNumber: number;
};
const ImageToVideoStepper = (props: Props) => {
  const {
    stepperConfiguration,
    selectedStepNumber
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return <Stepper orientation={isMobile ? 'vertical' : 'horizontal'} activeStep={selectedStepNumber} style={{
    padding: '15px'
  }}>
            {stepperConfiguration.map(({
      index,
      title
    }) => <Step key={index}>
                    <StepLabel>
                        <div style={{
          display: 'flex',
          flexDirection: 'column'
        }}>
                            <span>{title}</span>
                        </div>
                    </StepLabel>
                </Step>)}
        </Stepper>;
};
export default ImageToVideoStepper;