import React, { useCallback, useEffect } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { HomeStagingFormData } from '@components/office/front-office/home/home-staging/dialog/types/HomeStagingFormData';
import HomeStaging from '@components/office/front-office/home/home-staging/dialog/configuration/HomeStaging';
import useTheme from '@mui/system/useTheme';
import { useMediaQuery } from '@mui/material';
import { useIsMobile } from '@components/bridge/bridge/music-library/layout';
import { isEnvProd } from '@utils/getEnv';
type Props = {
  selectedStepNumber: number;
  stepCounter: number;
  formData: HomeStagingFormData;
  isLoading: boolean;
  generationSrc: string;
  onPrevious: () => void;
  onNext: () => void;
  onSubmit: () => void;
  onDownload: () => void;
};
const HomeStagingDialogActions = (props: Props) => {
  const {
    stepCounter,
    selectedStepNumber,
    formData,
    isLoading,
    generationSrc,
    onPrevious,
    onNext,
    onSubmit,
    onDownload
  } = props;
  const theme = useTheme();
  const isMobile = useIsMobile();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const isFirstStep = useCallback(() => selectedStepNumber === 0, [selectedStepNumber]);
  const isLastStep = useCallback(() => selectedStepNumber === stepCounter - 1, [stepCounter, selectedStepNumber]);
  const isBeforeLast = useCallback(() => selectedStepNumber === stepCounter - 2, [stepCounter, selectedStepNumber]);
  const isNextStepButtonEnabled = useCallback(() => HomeStaging.isValideStep(selectedStepNumber, formData), [formData, selectedStepNumber]);
  const handlePrevious = useCallback(() => onPrevious(), [onPrevious]);
  const handleNext = useCallback(() => onNext(), [onNext]);
  const handleSubmit = useCallback(() => onSubmit(), [onSubmit]);
  const handleDownload = useCallback(() => onDownload(), [onDownload]);
  const isBackButtonVisible = useCallback(() => {
    return !isFirstStep() && !isLoading;
  }, [isFirstStep, isLoading]);
  useEffect(() => {
    if (isLastStep()) {
      handleSubmit();
    }
  }, [isLastStep, handleSubmit]);
  return <DialogActions style={{
    paddingRight: isEnvProd && (isMobile || isTablet) ? '80px' : undefined
  }}>
            {isBackButtonVisible() && <Button onClick={handlePrevious}>{tr('home_staging.actions.previous')}</Button>}
            {!isLastStep() && <Button onClick={handleNext} disabled={!isNextStepButtonEnabled()}>
                    {isBeforeLast() ? tr('home_staging.actions.submit') : tr('home_staging.actions.next')}
                </Button>}
            {isLastStep() && <>
                    <Button onClick={handleSubmit} disabled={isLoading}>
                        {tr('home_staging.actions.retry')}
                    </Button>
                    <Button variant='contained' onClick={handleDownload} disabled={!generationSrc}>
                        {tr('home_staging.actions.download')}
                    </Button>
                </>}
        </DialogActions>;
};
export default HomeStagingDialogActions;