import { ImageToVideoStepConfiguration } from '@components/office/front-office/home/image-to-video/dialog/types/ImageToVideoStepConfiguration';
import { ImageToVideoFormData } from '@components/office/front-office/home/image-to-video/dialog/types/ImageToVideoFormData';
import { ImageToVideoPostBody } from '@components/office/front-office/home/image-to-video/dialog/types/ImageToVideoPostBody';
import { apiRequest, axios } from '@services/http/client';
import CameraMoveEnum from '@components/office/front-office/home/image-to-video/dialog/enums/ImageToVideoCameraMoveEnum';
const getStepperConfiguration = (): ImageToVideoStepConfiguration[] => [{
  index: 0,
  title: tr('image_to_video.steps.upload.stepper_config.title')
}, {
  index: 1,
  title: tr('image_to_video.steps.definition.stepper_config.title')
}, {
  index: 2,
  title: tr('image_to_video.steps.generate.stepper_config.title')
}];
const getInitialFormData = (): ImageToVideoFormData => {
  return {
    file: null,
    duration: 5,
    camera_move: CameraMoveEnum.AUTO,
    resolution: null
  };
};
const isValideStep = (selectedStepNumber: number, formData: ImageToVideoFormData) => {
  if (selectedStepNumber === 0 && formData.file) return true;
  if (selectedStepNumber === 1) {
    return formData.resolution && formData.camera_move !== undefined;
  }
};
const getGeneration = generationId => {
  if (!generationId) return Promise.resolve(null);
  return apiRequest(`
        query getImageToVideoPreGenerations {
            me {
                profile {
                     preGenerations {
                        imageToVideo(id: "${generationId}") {
                            id
                            startedAt
                            doneAt
                            done
                            inputFile {
                                src
                            }
                            outputFile {
                                 src
                            }
                         }
                     }
                  }
             }
           }
        `).then(data => {
    return data?.data?.me?.profile?.preGenerations?.imageToVideo?.[0];
  });
};
const getBodyFromFormData = (formData: ImageToVideoFormData): ImageToVideoPostBody => {
  return {
    file: formData.file,
    camera_move: formData.camera_move === CameraMoveEnum.AUTO ? CameraMoveEnum.DOLLY_IN : formData.camera_move,
    duration: formData.duration,
    resolution: formData.resolution
  };
};
const postGenerateImageToVideo = (formData: ImageToVideoFormData, setIsLoading, setGenerationSrc) => {
  setIsLoading(true);
  setGenerationSrc(null);
  const options = {
    method: 'POST',
    url: gen('write/me/generate-video-from-image'),
    data: getBodyFromFormData(formData),
    headers: {
      'Content-Type': 'application/json'
    },
    underWhitelabel: !window.areWeUnderDanimHost()
  };
  return axios(options).then(response => {
    if (response?.success) {
      const generationId = response?.data?.generation_id;
      if (generationId) {
        let test: number = 0;
        const polling = setInterval(() => {
          test++;
          if (test > 60) {
            setIsLoading(false);
            clearInterval(polling);
            return;
          }
          ImageToVideo.getGeneration(generationId).then(response => {
            if (response?.done) {
              setIsLoading(false);
              setGenerationSrc(response?.outputFile?.src);
              clearInterval(polling);
            }
          });
        }, 5000);
      } else {
        addFlash({
          type: 'error',
          message: "Une erreur est survenue lors de la génération de l'image"
        });
      }
    }
    return false;
  });
};
const downloadImage = async (src: string) => {
  if (!src) return;
  const partsOfUrl = src.split('/');
  const fileName = partsOfUrl[partsOfUrl.length - 1];
  const response = await fetch(src);
  const blob = await response.blob();
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(blobUrl);
};
const ImageToVideo = {
  getStepperConfiguration,
  getInitialFormData,
  isValideStep,
  getGeneration,
  postGenerateImageToVideo,
  getBodyFromFormData,
  downloadImage
};
export default ImageToVideo;