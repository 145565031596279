import { Variants } from 'framer-motion';
const menuAnim: Variants = {
  init: theme => ({
    display: 'flex',
    flexDirection: theme.breakpoints.down('sm') === 'xs' ? 'column' : 'row',
    flexWrap: 'wrap',
    gap: '1rem',
    top: 0,
    zIndex: 100,
    marginLeft: '-2rem',
    padding: '0rem 2rem',
    backgroundColor: 'white',
    width: '100%',
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 0.1
    },
    opacity: 1
  }),
  anim: {
    position: 'fixed',
    top: '3.5rem',
    minHeight: '5rem',
    alignItems: 'center',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
    padding: '1rem 2rem',
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 0.5
    }
  },
  exit: {
    position: 'sticky',
    top: '-4.5rem',
    boxShadow: 'none',
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 0
    }
  }
};
export default menuAnim;