import React, { useState } from 'react';
import ImageToVideoCameraMoveEnum from '@components/office/front-office/home/image-to-video/dialog/enums/ImageToVideoCameraMoveEnum';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
type MovementItem = {
  label: string;
  value: ImageToVideoCameraMoveEnum;
};
type MovementCategory = {
  items: MovementItem[];
};
type MovementSelectorProps = {
  value: ImageToVideoCameraMoveEnum | '';
  onChange: (value: ImageToVideoCameraMoveEnum) => void;
};
const MovementSelector: React.FC<MovementSelectorProps> = ({
  onChange,
  value
}) => {
  const [isAutoMode, setAutoMode] = useState<boolean>(true);
  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setAutoMode(isChecked);
    if (isChecked) {
      onChange(ImageToVideoCameraMoveEnum.AUTO);
    } else {
      onChange(ImageToVideoCameraMoveEnum.DOLLY_IN);
    }
  };
  const movements: MovementCategory[] = [{
    items: [{
      label: tr('image_to_video.movements.category.titles.forward'),
      value: ImageToVideoCameraMoveEnum.DOLLY_IN
    }, {
      label: tr('image_to_video.movements.category.titles.backward'),
      value: ImageToVideoCameraMoveEnum.DOLLY_OUT
    }]
  }, {
    items: [{
      label: tr('image_to_video.movements.category.titles.up'),
      value: ImageToVideoCameraMoveEnum.CRANE_UP
    }, {
      label: tr('image_to_video.movements.category.titles.down'),
      value: ImageToVideoCameraMoveEnum.CRANE_DOWN
    }]
  }, {
    items: [{
      label: tr('image_to_video.movements.category.titles.pan_left'),
      value: ImageToVideoCameraMoveEnum.PAN_LEFT
    }, {
      label: tr('image_to_video.movements.category.titles.pan_right'),
      value: ImageToVideoCameraMoveEnum.PAN_RIGHT
    }]
  }];
  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    gap: '10px'
  }}>
            <Typography variant='h6'>{tr('image_to_video.steps.definition.stepper_config.title')}</Typography>
            <FormControlLabel control={<Switch onChange={handleSwitchChange} checked={isAutoMode} inputProps={{
      'aria-label': 'auto-detect movement'
    }} />} label={tr('image_to_video.steps.definition.auto_mode.title')} />
            {!isAutoMode && <div style={{
      fontWeight: 'bold'
    }}>{tr('image_to_video.tips')}</div>}

            {!isAutoMode && <div style={{
      display: 'flex',
      alignItems: 'flex-start',
      overflowX: 'auto'
    }}>
                    {movements.map((category, index) => <div key={index} style={{
        gap: '10px',
        padding: '10px',
        textAlign: 'center'
      }}>
                            <div style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
          gap: '10px'
        }}>
                                {category.items.map(item => <button key={item.value} onClick={() => onChange(item.value)} style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '5px',
            padding: '10px',
            borderRadius: '15px',
            cursor: 'pointer',
            background: 'white',
            border: 'none',
            borderColor: value === item.value ? '#3294a5' : 'transparent',
            opacity: isAutoMode ? 0.4 : 1,
            pointerEvents: isAutoMode ? 'none' : 'auto'
          }}>
                                        <img src={`/img/gif/camera_move/${item.value}.gif`} alt={item.label} style={{
              width: '100px',
              height: '100px',
              objectFit: 'cover',
              borderRadius: '15px',
              padding: '4px',
              border: value === item.value ? '3px solid #3294a5' : 'none',
              boxSizing: 'border-box'
            }} />
                                        <span style={{
              fontSize: '14px',
              color: '#555'
            }}>{item.label}</span>
                                    </button>)}
                            </div>
                        </div>)}
                </div>}
        </div>;
};
export default MovementSelector;