function queryExhaustive(workspaceID: string, libraryIds: string[]) {
    return `{
            organizationTemplates:templates(
                       ${workspaceID ? `inWorkspace: "${workspaceID}"` : 'organizationRoot: true'}
                       exhaustive: true
                   ) {
                       edges {
                           node {
                               id
                               shortId
                           }
                       }
                   }
                   libraryTemplates:templates(
                       inLibrary: "${libraryIds.join(' | ')}"
                       exhaustive: true
                   ) {
                       edges {
                           node {
                               id
                               shortId
                           }
                       }
                   }
        }
        `;
}

const queryExhaustiveMock = () => 'FrontExhaustive';

const queryFrontExhaustiveApi = process.env.NEXT_PUBLIC_MOCKS === 'true' ? queryExhaustiveMock : queryExhaustive;

export default queryFrontExhaustiveApi;
