import React, { useCallback, useEffect } from 'react';
import { CustomDialogContent } from '@components/bridge/bridge/DraggableHelperDialog.styles';
import { ImageToVideoStepConfiguration } from '@components/office/front-office/home/image-to-video/dialog/types/ImageToVideoStepConfiguration';
import ImageToVideoStepper from '@components/office/front-office/home/image-to-video/dialog/ImageToVideoStepper';
import { ImageToVideoFormData } from '@components/office/front-office/home/image-to-video/dialog/types/ImageToVideoFormData';
import ImageToVideoDefinitionStep from '@components/office/front-office/home/image-to-video/dialog/steps/definitions/ImageToVideoDefinitionStep';
import ImageToVideoUploadStep from '@components/office/front-office/home/image-to-video/dialog/steps/upload/ImageToVideoUploadstep';
import ImageToVideoGenerateStep from '@components/office/front-office/home/image-to-video/dialog/steps/generate/ImageToVideoGenerateStep';
type Props = {
  stepperConfiguration: ImageToVideoStepConfiguration[];
  isLoading: boolean;
  generationSrc: string;
  selectedStepNumber: number;
  formData: ImageToVideoFormData;
  onChangeFormData: (key: string, value: string | any) => void;
};
const ImageToVideoDialogContent = (props: Props) => {
  const {
    stepperConfiguration,
    selectedStepNumber,
    formData,
    isLoading,
    generationSrc,
    onChangeFormData
  } = props;
  const handleChangeFormData = useCallback((key: string, value: string | any) => {
    onChangeFormData(key, value);
  }, [onChangeFormData]);
  useEffect(() => {}, [formData]);
  return <CustomDialogContent dividers style={{
    display: 'flex',
    flexDirection: 'column',
    height: '70vh',
    overflowY: 'auto'
  }}>
            <ImageToVideoStepper stepperConfiguration={stepperConfiguration} selectedStepNumber={selectedStepNumber} />
            {selectedStepNumber === 0 && <ImageToVideoUploadStep file={formData.file} onChange={event => handleChangeFormData('file', event)} />}
            {selectedStepNumber === 1 && <ImageToVideoDefinitionStep onChange={(field, value) => handleChangeFormData(field, value)} selectedOptionResolution={formData.resolution} selectedOptionCamera={formData.camera_move} />}
            {selectedStepNumber === 2 && <ImageToVideoGenerateStep formData={formData} isLoading={isLoading} generationSrc={generationSrc} />}
        </CustomDialogContent>;
};
export default ImageToVideoDialogContent;