import React from 'react';
import { IconButton, Stack, useMediaQuery, Zoom } from '@mui/material';
import useTheme from '@mui/system/useTheme';
import dynamic from 'next/dynamic';
import SwiperContainer from '@components/bridge/bridge/slider/SwiperContainer';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import InfoIcon from '@mui/icons-material/Info';
import StarIcon from '@mui/icons-material/Star';
import { CategoryContainer, CategoryTitle, CategoryTitleContainer, VisibilityContainer } from '@components/office/front-office/home/Category.styles';
import NextLink from 'next/link';
import Tooltip from '@mui/material/Tooltip';
import useSWR from 'swr';
import { apiSwrRequest } from '@services/http/client';
import queryFrontCategoryApi from '@queries/api/office/front-office/category/queryCategory';
import { SwiperSlide } from 'swiper/react';
import { StyledSkeleton } from '@components/bridge/bridge/video-card/VideoCard.styles';
const VideoCard = dynamic(() => import('@components/bridge/bridge/video-card/VideoCard'));
export default function Category({
  appuser,
  workspace,
  id,
  title,
  description,
  totalCount,
  libraryIds,
  favourites
}) {
  const isFavourites = id === 'favourites';
  const {
    data: category,
    isLoading,
    isValidating
  } = useSWR(isFavourites ? null : () => queryFrontCategoryApi(id, false, appuser.isOrganizationDirector() ? 'NOT_STRICTLY' : 'NOT_BROADLY', workspace ? workspace.id : null, libraryIds), apiSwrRequest);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // We concat the library to organization templates
  // to get all templates and then we find the exhaustive template by priority
  // (organization > library)
  const allTemplates = [...(category?.categoryPayloadTemplates?.templates?.edges || []), ...(category?.categoryPayloadLibrary?.templates?.edges || [])];

  // filter templates by id which are in the templatesIds array
  // and sort them by the props isConsideredAsNew
  // and sort them by the props isConsideredAsNewAt
  // and sort them by the props createdAt
  const sortedTemplates = isFavourites ? null : allTemplates?.map(edge => {
    if (!edge?.node?.id) return null;
    return edge.node;
  }).sort((a, b) => {
    if (!a || !b) return 0;
    if (a.consideredAsNew && !b.consideredAsNew) {
      return -1;
    }
    if (!a.consideredAsNew && b.consideredAsNew) {
      return 1;
    }
    if (a.consideredAsNewAt > b.consideredAsNewAt) {
      return -1;
    }
    if (a.consideredAsNewAt < b.consideredAsNewAt) {
      return 1;
    }
    if (a.createdAt > b.createdAt) {
      return -1;
    }
    if (a.createdAt < b.createdAt) {
      return 1;
    }
    return 0;
  });
  return <CategoryContainer>
            <Stack alignItems='center' direction='row'>
                <CategoryTitleContainer>
                    {id === 'favourites' && <StarIcon color='yellow' fontSize='large' />}
                    <NextLink href={`/category/${id}`} passHref>
                        <CategoryTitle variant='h4'>{title}</CategoryTitle>
                    </NextLink>
                    {!isMobile && description && description.length > 0 && <Tooltip arrow placement='top' TransitionComponent={Zoom} title={description}>
                            <InfoIcon color='primary' />
                        </Tooltip>}
                    {isMobile && <NextLink href={`/category/${id}`} passHref>
                            <IconButton>
                                <ViewAgendaIcon />
                            </IconButton>
                        </NextLink>}
                </CategoryTitleContainer>
            </Stack>
            <SwiperContainer id={id} isLoading={isLoading || isValidating} totalCount={totalCount} renderSkeleton={() => <StyledSkeleton variant='rounded' />}>
                {!isFavourites && sortedTemplates?.map((template, i) => {
        if (!template?.id) return null;
        return <SwiperSlide key={i}>
                                <VisibilityContainer>
                                    <VideoCard appuser={appuser} template={template} workspace={workspace} isMobile={isMobile} favourites={favourites} libraryIds={libraryIds} />
                                </VisibilityContainer>
                            </SwiperSlide>;
      })}
                {isFavourites && favourites?.map((template, i) => {
        if (!template?.id) return null;
        return <SwiperSlide key={i}>
                                <VisibilityContainer>
                                    <VideoCard appuser={appuser} template={template} workspace={workspace} isMobile={isMobile} favourites={favourites} libraryIds={libraryIds} />
                                </VisibilityContainer>
                            </SwiperSlide>;
      })}
            </SwiperContainer>
        </CategoryContainer>;
}