import React from 'react';
import { ImageToVideoFormData } from '@components/office/front-office/home/image-to-video/dialog/types/ImageToVideoFormData';
import { CircularProgress, useMediaQuery } from '@mui/material';
import useTheme from '@mui/system/useTheme';
type Props = {
  formData: ImageToVideoFormData;
  isLoading: boolean;
  generationSrc: string;
};
const ImageToVideoGenerateStep = (props: Props) => {
  const {
    formData,
    isLoading,
    generationSrc
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '10px'
  }}>
            {isLoading && <div style={{
      paddingLeft: '30px',
      paddingRight: '30px',
      fontStyle: 'italic'
    }}>
                    {tr('image_to_video.steps.generate.summary_sentence')}
                </div>}
            <div style={{
      display: 'flex',
      gap: '10px',
      maxHeight: !isMobile ? '400px' : undefined,
      flexDirection: !isMobile ? 'row' : 'column'
    }}>
                <img src={formData.file.url} alt='' style={{
        outline: '1px solid grey',
        flexGrow: 1,
        width: !isMobile ? '250px' : undefined,
        maxHeight: '400px',
        objectFit: 'contain',
        maxWidth: isMobile ? '400px' : undefined
      }} />
                {isLoading && <div style={{
        outline: '1px solid grey',
        flexGrow: 1,
        width: !isMobile ? '250px' : undefined,
        maxHeight: '400px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: isMobile ? '200px' : undefined,
        maxWidth: isMobile ? '400px' : undefined
      }}>
                        <CircularProgress />
                    </div>}
                {!isLoading && <video controls src={generationSrc} height='auto' style={{
        outline: '1px solid grey',
        flexGrow: 1,
        width: !isMobile ? '250px' : undefined,
        maxHeight: '400px',
        objectFit: 'contain',
        maxWidth: isMobile ? '400px' : undefined
      }} />}
            </div>
            <div style={{
      display: 'flex',
      justifyContent: 'flex-end'
    }} />
        </div>;
};
export default ImageToVideoGenerateStep;