import React, { useEffect, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import { motion } from 'framer-motion';
import useTheme from '@mui/system/useTheme';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import useWindowScroll from '@hooks/useWindowScroll';
import AnimatedButton from '@components/office/front-office/home/AnimatedButton';
import menuAnim from '@components/office/front-office/home/MenuButtons.anim';
import MenuContainer from '@components/office/front-office/home/MenuButtons.styles';
import { NextRouter } from 'next/router';
import HomeStagingButton from '@components/office/front-office/home/home-staging/HomeStagingButton';
import ImageToVideoButton from '@components/office/front-office/home/image-to-video/ImageToVideoButton';
const AIDialog = dynamic(() => import('@components/office/front-office/home/dialogs/AIDialog'), {
  ssr: false
});
interface Props {
  appuser: {
    id: string;
    account: {
      email: string;
    };
    canI: {
      getUiModuleTo: {
        generateVideoViaAiCompletion: boolean;
        startHomeStagingImageGeneration: boolean;
        startImageToVideoGeneration: {
          itself: boolean;
          detectMyIntentToProceed: boolean;
          _remainingGenerationsForThisMonth: number;
          _generationsLimitPerMonth: number;
        };
      };
    };
  };
  exhaustiveTemplate?: {
    shortId: string;
  };
  router: NextRouter;
}
const MenuButtons: React.FC<Props> = props => {
  const {
    appuser,
    exhaustiveTemplate,
    router
  } = props;
  const theme = useTheme();
  const openAIId = useRef<number>(1);
  const [openAI, setOpenAI] = useState<boolean>(false);
  const [{
    y
  }] = useWindowScroll();
  const handleOpenAI = () => setOpenAI(true);
  const handleCloseAI = () => setOpenAI(false);
  useEffect(() => {
    if (!openAI) openAIId.current += 1;
  }, [openAI]);
  return <>
            <MenuContainer>
                <motion.div initial='init' animate={y > 500 ? 'anim' : y < 150 ? 'init' : 'exit'} variants={menuAnim} custom={theme}>
                    {appuser.canI.getUiModuleTo.generateVideoViaAiCompletion && <>
                            <AIDialog key={openAIId.current} open={openAI} handleClose={handleCloseAI} appuser={appuser} title={tr('front.create_with_data')} />
                            <AnimatedButton handleClick={handleOpenAI} label={tr('front.buttons.create_ia')} tooltip={tr('front.buttons.ai.tooltip')} hideTooltipOnMobile />
                        </>}
                    {appuser.canI.getUiModuleTo.startHomeStagingImageGeneration && <HomeStagingButton />}
                    {exhaustiveTemplate && <AnimatedButton minWidth='14rem' handleClick={() => router.push(`/video/new?templateShortId=${exhaustiveTemplate.shortId}`)} label={tr('front.buttons.exhaustive.title')} icon={<SortByAlphaIcon />} />}
                    {appuser.canI.getUiModuleTo?.startImageToVideoGeneration?.itself && <ImageToVideoButton userEmail={appuser.account.email} remainingGenerations={appuser.canI.getUiModuleTo.startImageToVideoGeneration._remainingGenerationsForThisMonth} generationsLimit={appuser.canI.getUiModuleTo.startImageToVideoGeneration._generationsLimitPerMonth} isActive={appuser.canI.getUiModuleTo.startImageToVideoGeneration.detectMyIntentToProceed} />}
                </motion.div>
            </MenuContainer>
        </>;
};
export default MenuButtons;