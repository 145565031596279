import React, { useCallback, useState } from 'react';
import { Fade, useMediaQuery } from '@mui/material';
import useTheme from '@mui/system/useTheme';
import DanimModal from '@components/bridge/bridge/DanimModal';
import { CustomDialogTitle } from '@components/bridge/bridge/DraggableHelperDialog.styles';
import HomeStagingDialogActions from '@components/office/front-office/home/home-staging/dialog/HomeStagingDialogActions';
import HomeStaging from '@components/office/front-office/home/home-staging/dialog/configuration/HomeStaging';
import { HomeStagingFormData } from '@components/office/front-office/home/home-staging/dialog/types/HomeStagingFormData';
import ModalRouterChangeConfirm from '@components/office/front-office/home/home-staging/dialog/confirm/ModalRouterChangeConfirm';
import HomeStagingDialogContent from '@components/office/front-office/home/home-staging/dialog/HomeStagingDialogContent';
import Button from '@mui/material/Button';
import ModalConfirm from '@components/office/front-office/home/home-staging/dialog/confirm/ModalConfirm';
import { HomeStagingStepConfiguration } from '@components/office/front-office/home/home-staging/dialog/types/HomeStagingStepConfiguration';
import HomeStagingKindEnum from '@components/office/front-office/home/home-staging/dialog/enums/HomeStagingKindEnum';
import HomeStagingTreatmentEnum from '@components/office/front-office/home/home-staging/dialog/enums/HomeStagingTreatmentEnum';
import Lightbulb from '@mui/icons-material/Lightbulb';
import DraggableHelperDialog from '@components/bridge/bridge/DraggableHelperDialog';
type Props = {
  open: boolean;
  onClose: () => void;
};
const HomeStagingDialog = (props: Props) => {
  const {
    open,
    onClose
  } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [activeStep, setActiveStep] = useState(0);
  const stepperConfiguration: HomeStagingStepConfiguration[] = HomeStaging.getStepperConfiguration();
  const [formData, setFormData] = useState<HomeStagingFormData>(HomeStaging.getInitialFormData());
  const [generationSrc, setGenerationSrc] = useState<string>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isClosable, setIsClosable] = useState<boolean>(true);
  const [isOpenModalPreviousConfirm, setIsOpenModalPreviousConfirm] = useState<boolean>(false);
  const [isOpenModalCloseConfirm, setIsOpenModalCloseConfirm] = useState<boolean>(false);
  const handleSubmit = useCallback(async () => {
    setIsClosable(false);
    await HomeStaging.postGenerateImageWithPolling(formData, setIsLoading, setGenerationSrc);
  }, [formData]);
  const handleDownload = useCallback(async () => {
    setIsClosable(true);
    await HomeStaging.downloadImage(generationSrc);
  }, [generationSrc]);
  const handleClose = useCallback(() => {
    if (isClosable) {
      resetModal();
      onClose();
    } else {
      setIsOpenModalCloseConfirm(true);
    }
  }, [isClosable, onClose]);
  const handleForceClose = useCallback(() => {
    resetModal();
    onClose();
  }, [onClose]);
  const handlePrevious = useCallback(() => {
    if (isClosable) {
      setActiveStep(current => current - 1);
    } else {
      setIsOpenModalPreviousConfirm(true);
    }
  }, [isClosable]);
  const handlePreviousConfirm = () => {
    setActiveStep(current => current - 1);
    setIsClosable(true);
    setIsOpenModalPreviousConfirm(false);
  };
  const resetModal = () => {
    setActiveStep(0);
    setFormData(HomeStaging.getInitialFormData());
    setIsClosable(true);
    setIsLoading(false);
    setGenerationSrc(null);
    setIsOpenModalCloseConfirm(false);
  };
  const handleChangeFormData = useCallback((key: string, value: string | any) => {
    setFormData(currentState => {
      const newFormData = {
        ...currentState,
        [key]: value
      };
      if (key === 'room') {
        newFormData.style = null;
        newFormData.outRoom = value.isAlsoFillType ? value : null;
        newFormData.blueSkyMode = false;
        if (value?.kind === HomeStagingKindEnum.OUTSIDE && newFormData?.treatment === HomeStagingTreatmentEnum.FURNISH) {
          newFormData.treatment = null;
        }
      }
      return newFormData;
    });
  }, []);
  return <DanimModal handleClose={() => {}} open={open} maxWidth='md' fullWidth fullScreen={fullScreen} TransitionComponent={Fade} TransitionProps={{
    timeout: 500
  }} disableEscapeKeyDown>
            <ModalRouterChangeConfirm isLoading={isLoading} />
            <ModalConfirm isOpen={isOpenModalCloseConfirm} onCancel={() => setIsOpenModalCloseConfirm(false)} onConfirm={handleForceClose} isLoading={isLoading} />
            <ModalConfirm isOpen={isOpenModalPreviousConfirm} onCancel={() => setIsOpenModalPreviousConfirm(false)} onConfirm={handlePreviousConfirm} isLoading={isLoading} />
            <CustomDialogTitle style={{
      display: 'flex',
      justifyContent: 'space-between'
    }}>
                <div>{tr('home_staging.title')}</div>
                <div style={{
        display: 'flex',
        gap: '12px'
      }}>
                    <DraggableHelperDialog dialogTitle={tr('front.home.dialogs.homestaging.helper.title')} draggableId='draggable-custom-homestaging' url='https://www.youtube.com/embed/HXmiVHQdF6M' ctaText={tr('front.home.dialogs.homestaging.helper.cta')} ctaUrl='https://help.danim.com/fr/articles/9669835-le-home-staging' ctaIcon={<Lightbulb />} chipSize='medium' />
                    <Button onClick={handleClose}>X</Button>
                </div>
            </CustomDialogTitle>
            <HomeStagingDialogContent stepperConfiguration={stepperConfiguration} selectedStepNumber={activeStep} formData={formData} onChangeFormData={handleChangeFormData} isLoading={isLoading} generationSrc={generationSrc} />
            <HomeStagingDialogActions stepCounter={stepperConfiguration.length} selectedStepNumber={activeStep} formData={formData} onPrevious={handlePrevious} onNext={() => setActiveStep(current => current + 1)} onSubmit={handleSubmit} onDownload={handleDownload} isLoading={isLoading} generationSrc={generationSrc} />
        </DanimModal>;
};
export default HomeStagingDialog;