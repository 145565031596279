import React, { useState, useEffect } from 'react';
import ImageToVideoResolutionEnum from '@components/office/front-office/home/image-to-video/dialog/enums/ImageToVideoResolutionEnum';
import ResolutionOption from '@components/office/front-office/home/image-to-video/dialog/steps/definitions/resolution-choice/ResolutionOption';
type ResolutionChoiceProps = {
  value: ImageToVideoResolutionEnum | null;
  onChange: (resolution: ImageToVideoResolutionEnum) => void;
};
const ImageToVideoResolutionChoice: React.FC<ResolutionChoiceProps> = ({
  value,
  onChange
}) => {
  const [selectedResolution, setSelectedResolution] = useState<ImageToVideoResolutionEnum | null>(null);
  useEffect(() => {
    setSelectedResolution(value);
  }, [value]);
  const handleResolutionChange = (resolution: ImageToVideoResolutionEnum) => {
    setSelectedResolution(resolution);
    onChange(resolution);
  };
  return <div style={{
    alignItems: 'flex-start',
    gap: '20px',
    padding: '20px 0',
    overflowX: 'auto'
  }}>
            <h3 style={{
      marginBottom: '10px',
      fontSize: '18px',
      fontWeight: 'bold',
      color: '#333'
    }}>
                {tr('image_to_video.steps.resolution.stepper_config.title')}
            </h3>
            <div style={{
      display: 'flex',
      gap: '40px'
    }}>
                <ResolutionOption resolution={ImageToVideoResolutionEnum.LANDSCAPE} isSelected={selectedResolution === ImageToVideoResolutionEnum.LANDSCAPE} onClick={() => handleResolutionChange(ImageToVideoResolutionEnum.LANDSCAPE)} label={tr('image_to_video.steps.resolution.stepper_config.landscape')} width='80px' height='40px' />
                <ResolutionOption resolution={ImageToVideoResolutionEnum.PORTRAIT} isSelected={selectedResolution === ImageToVideoResolutionEnum.PORTRAIT} onClick={() => handleResolutionChange(ImageToVideoResolutionEnum.PORTRAIT)} label={tr('image_to_video.steps.resolution.stepper_config.portrait')} width='40px' height='80px' />
            </div>
        </div>;
};
export default ImageToVideoResolutionChoice;