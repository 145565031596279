import { useLayoutEffect, useState } from 'react';
export const mobileBreakpointPixelsNumber = 600;
export const mobileBreakpoint = `${mobileBreakpointPixelsNumber}px`;
export const useIsMobile = () => {
  const [windowWidth, setWindowWidth] = useState(0);
  const handleSize = () => {
    setWindowWidth(window.innerWidth);
  };
  useLayoutEffect(() => {
    handleSize();
    window.addEventListener('resize', handleSize);
    return () => window.removeEventListener('resize', handleSize);
  }, []);
  return windowWidth < mobileBreakpointPixelsNumber;
};