import React, { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import ModalConfirm from '@components/office/front-office/home/home-staging/dialog/confirm/ModalConfirm';
type Props = {
  isLoading: boolean;
};
const ModalRouterChangeConfirm = (props: Props) => {
  const {
    isLoading
  } = props;
  const router = useRouter();
  const [isOpenModalExitConfirm, setIsOpenModalExitConfirm] = useState<boolean>(false);
  const [isForceRedirect, setIsForceRedirect] = useState<boolean>(false);
  const [savingNextRoute, setSavingNextRoute] = useState<string>('');
  useEffect(() => {
    const exitingFunction = (url: string) => {
      if (!isForceRedirect) {
        setIsForceRedirect(false);
        setSavingNextRoute(url);
        setIsOpenModalExitConfirm(true);
        router.events.emit('routeChangeError');
        // eslint-disable-next-line no-throw-literal
        throw 'routeChange aborted.';
      }
    };
    router.events.on('routeChangeStart', exitingFunction);
    return () => {
      router.events.off('routeChangeStart', exitingFunction);
    };
  }, [router, isForceRedirect]);
  const handleConfirm = useCallback(() => {
    setIsForceRedirect(true);
    setIsOpenModalExitConfirm(false);
    if (savingNextRoute !== '') {
      router.push(savingNextRoute);
    }
  }, [router, savingNextRoute]);
  const handleCancel = useCallback(() => {
    setIsOpenModalExitConfirm(false);
  }, []);
  return <ModalConfirm isOpen={isOpenModalExitConfirm} isLoading={isLoading} onCancel={handleCancel} onConfirm={handleConfirm} />;
};
export default ModalRouterChangeConfirm;