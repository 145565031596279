import React, { useCallback } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
type Props = {
  isOpen: boolean;
  isLoading: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};
const ModalCloseConfirm = (props: Props) => {
  const {
    isOpen,
    isLoading,
    onCancel,
    onConfirm
  } = props;
  const handleConfirm = useCallback(() => {
    onConfirm();
  }, [onConfirm]);
  const handleCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);
  return <Dialog open={isOpen} onClose={handleCancel} maxWidth='sm' fullWidth>
            <DialogTitle>{tr(`image_to_video.modal_exit_confirm.title`)}</DialogTitle>
            <DialogContent>
                {isLoading ? tr(`image_to_video.modal_exit_confirm.content_in_progress`) : tr(`image_to_video.modal_exit_confirm.content_not_download`)}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>{tr(`image_to_video.modal_exit_confirm.cancel_text`)}</Button>
                <Button onClick={handleConfirm}>{tr(`image_to_video.modal_exit_confirm.confirm_text`)}</Button>
            </DialogActions>
        </Dialog>;
};
export default ModalCloseConfirm;