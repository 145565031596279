import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { LinearProgress } from '@mui/material';
import Select from '@mui/material/Select';
type Props = {
  label: string;
};
const WaitingSelect = (props: Props) => {
  const {
    label
  } = props;
  return <Select value='-' label={label}>
            <MenuItem value='-'>
                <LinearProgress style={{
        top: '10px'
      }} />
            </MenuItem>
        </Select>;
};
export default WaitingSelect;