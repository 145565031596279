import React, { useCallback, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import HomeStaging from '@components/office/front-office/home/home-staging/dialog/configuration/HomeStaging';
import { HomeStagingRoom } from '@components/office/front-office/home/home-staging/dialog/types/HomeStagingRoom';
import { HomeStagingStyle } from '@components/office/front-office/home/home-staging/dialog/types/HomeStagingStyle';
type Props = {
  style: HomeStagingStyle;
  room: HomeStagingRoom;
  onChange: (value: HomeStagingStyle) => void;
};
const RoomSelector = (props: Props) => {
  const {
    style,
    room,
    onChange
  } = props;
  const [selectedStyle, setSelectedStyle] = useState<HomeStagingStyle>(style);
  const [items, setItems] = useState<HomeStagingStyle[]>([]);
  useEffect(() => {
    HomeStaging.getStyles(room).then(response => {
      setItems(response);
    });
  }, [room]);
  const handleChange = useCallback((name: string) => {
    const style: HomeStagingStyle = items.find(item => item.name === name);
    setSelectedStyle(style);
    onChange(style);
  }, [onChange, items]);
  const isItemSelected = useCallback(name => {
    return selectedStyle && selectedStyle?.name === name;
  }, [selectedStyle]);
  return <>
            <Typography variant='h6'>{tr('home_staging.steps.definition.style.title')}</Typography>
            <div className='mosaic-container' style={{
      display: 'flex',
      flexWrap: 'wrap',
      gap: '10px',
      width: '100%',
      height: '400px',
      overflowY: 'auto',
      border: '1px solid #c0c0c0',
      borderRadius: '5px',
      padding: '6px'
    }}>
                {items.map(({
        name,
        label,
        exampleImageUrl
      }, index) => <div key={index} onClick={() => handleChange(name)}>
                        <div style={{
          position: 'relative',
          display: 'inline-block',
          width: '188px',
          height: '188px'
        }}>
                            <img src={exampleImageUrl} alt={label} style={{
            display: 'block',
            width: '100%',
            borderTopRightRadius: '3px',
            borderTopLeftRadius: '3px'
          }} />
                            <div style={{
            position: 'absolute',
            top: '0',
            left: '0',
            right: '0',
            color: 'white',
            backgroundColor: isItemSelected(name) ? '#3294A5' : 'rgba(0, 0, 0, 0.5)',
            borderTopRightRadius: '3px',
            borderTopLeftRadius: '3px',
            paddingLeft: '5px'
          }}>
                                {label}
                            </div>
                            {isItemSelected(name) && <span style={{
            position: 'absolute',
            top: '30px',
            left: '0',
            right: '0',
            bottom: '0',
            fontSize: '100px',
            textAlign: 'center',
            color: 'white'
          }}>
                                    ✔
                                </span>}
                        </div>
                    </div>)}
            </div>
        </>;
};
export default RoomSelector;