import React, { useCallback, useState } from 'react';
import Typography from '@mui/material/Typography';
import dynamic from 'next/dynamic';
import { serialize } from 'object-to-formdata';
import { axios } from '@services/http/client';
import { getAbsoluteUrl } from '@utils/filePath';
type Props = {
  file: any;
  onChange: (file: any) => void;
};
const DanimUploader = dynamic(() => import('@components/bridge/bridge/Uploader'), {
  ssr: false
});
const ImageToVideoUploadStep = (props: Props) => {
  const {
    file,
    onChange
  } = props;
  const [src, setSrc] = useState(file?.src || null);
  const [progress, setProgress] = useState<number>(100);
  const getImageResolution = (file: File): Promise<{
    width: number;
    height: number;
    ratio: number;
  }> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        resolve({
          width,
          height,
          ratio: width / height
        });
      };
      img.onerror = reject;
      img.src = URL.createObjectURL(file);
    });
  };
  const handleChange = useCallback(async file => {
    if (!file) return;
    const fileSizeOctets: number = file.size;
    if (fileSizeOctets > 16777216) {
      addFlash({
        id: 'uploadFileError',
        message: tr('image_to_video.steps.upload.errors.file_size'),
        type: 'warning'
      });
      return;
    }
    const {
      ratio
    } = await getImageResolution(file);
    if (ratio < 0.5 || ratio > 2) {
      addFlash({
        id: 'uploadFileError',
        message: tr('image_to_video.steps.upload.errors.file_ratio'),
        type: 'warning'
      });
      return;
    }
    const resolution = await getImageResolution(file);
    if (resolution && (resolution.width > 8000 || resolution.height > 8000)) {
      addFlash({
        id: 'uploadFileError',
        message: tr('image_to_video.steps.upload.errors.file_resolution'),
        type: 'warning'
      });
      return;
    }
    setProgress(0);
    const options = {
      method: 'POST',
      url: gen('upload/me'),
      data: serialize({
        file
      }, {
        indices: true
      }),
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent: ProgressEvent) => {
        const percentCompleted = Math.round(progressEvent.loaded * 100 / (progressEvent.total || 1));
        setProgress(percentCompleted);
      },
      refreshTokenJustBefore: true
    };
    await axios(options).then(data => {
      if (data.success) {
        onChange(data.data.file);
        setSrc(data.data.file.src);
      }
    }).catch(error => {
      console.error(error);
    });
  }, [onChange]);
  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
    padding: '10px'
  }}>
            <Typography variant='h6'>{tr('image_to_video.steps.upload.title')}</Typography>
            <div style={{
      width: '377px'
    }}>
                <DanimUploader src={getAbsoluteUrl(src)} fileType={['image']} onChange={file => handleChange(file)} style={{
        width: '200px'
      }} />
                {progress < 100 && <progress value={progress} max='100' style={{
        width: '100%'
      }} />}
            </div>
        </div>;
};
export default ImageToVideoUploadStep;