import React from 'react';
import Typography from '@mui/material/Typography';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { HomeStagingRoom } from '@components/office/front-office/home/home-staging/dialog/types/HomeStagingRoom';
type Props = {
  strength: number;
  handleStrengthChange: (event: React.MouseEvent<HTMLElement>, newStrength: number | null) => void;
  room: HomeStagingRoom;
};
const StrengthSelector = (props: Props) => {
  const {
    strength,
    handleStrengthChange,
    room
  } = props;
  const getStrengthImage = (strength: number) => {
    switch (strength) {
      case 0.5:
        return `/img/jpg/homestaging/${room?.kind.toLowerCase()}-safe.jpg`;
      case 0.6:
        return `/img/jpg/homestaging/${room?.kind.toLowerCase()}-moderate.jpg`;
      case 0.75:
        return `/img/jpg/homestaging/${room?.kind.toLowerCase()}-creative.jpg`;
      default:
        return `/img/jpg/homestaging/${room?.kind.toLowerCase()}-original.jpg`;
    }
  };
  const getStrengthLabel = (strength: number) => {
    switch (strength) {
      case 0.5:
        return tr('home_staging.steps.options.strength.levels.safe');
      case 0.6:
        return tr('home_staging.steps.options.strength.levels.moderate');
      case 0.75:
        return tr('home_staging.steps.options.strength.levels.creative');
      default:
        return tr('home_staging.steps.options.strength.levels.original');
    }
  };
  return <>
            <Typography variant='h6'>{tr('home_staging.steps.options.strength.title')}</Typography>
            <ToggleButtonGroup exclusive value={strength} onChange={handleStrengthChange} fullWidth>
                <ToggleButton value={0.5}>{tr('home_staging.steps.options.strength.levels.safe')}</ToggleButton>
                <ToggleButton value={0.6}>{tr('home_staging.steps.options.strength.levels.moderate')}</ToggleButton>
                <ToggleButton value={0.75}>{tr('home_staging.steps.options.strength.levels.creative')}</ToggleButton>
            </ToggleButtonGroup>

            <p style={{
      marginBottom: 0
    }}>
                {tr('home_staging.steps.options.strength.example')}
            </p>
            <div style={{
      display: 'flex',
      gap: '10px',
      justifyContent: 'center'
    }}>
                <div style={{
        position: 'relative',
        flex: '1'
      }}>
                    <img src={`/img/jpg/homestaging/${room?.kind.toLowerCase()}-original.jpg`} alt={tr('home_staging.steps.options.strength.levels.original')} style={{
          width: '100%',
          height: 'auto',
          borderRadius: '3px'
        }} />
                    <Typography variant='caption' style={{
          position: 'absolute',
          bottom: '8px',
          right: '8px',
          backgroundColor: 'white',
          padding: '2px 6px',
          borderRadius: '3px'
        }}>
                        {tr('home_staging.steps.options.strength.levels.original')}
                    </Typography>
                </div>
                <div style={{
        position: 'relative',
        flex: '1'
      }}>
                    <img src={getStrengthImage(strength)} alt={getStrengthLabel(strength)} style={{
          width: '100%',
          height: 'auto',
          borderRadius: '3px'
        }} />
                    <Typography variant='caption' style={{
          position: 'absolute',
          bottom: '8px',
          right: '8px',
          backgroundColor: 'white',
          padding: '2px 6px',
          borderRadius: '3px'
        }}>
                        {getStrengthLabel(strength)}
                    </Typography>
                </div>
            </div>
        </>;
};
export default StrengthSelector;