import React, { useCallback, useState } from 'react';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
type Props = {
  blueSkyMode: boolean;
  onChange: (value: boolean) => void;
};
const BlueSkySelector = (props: Props) => {
  const {
    blueSkyMode,
    onChange
  } = props;
  const [isBlueSkyMode, setIsBlueSkyMode] = useState<boolean>(blueSkyMode);
  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newBlueSkyModeState: boolean = event.target.checked;
    setIsBlueSkyMode(newBlueSkyModeState);
    onChange(newBlueSkyModeState);
  }, [onChange]);
  return <>
            <Typography variant='h6'>{tr('home_staging.steps.definition.blue_sky_mode.title')}</Typography>
            <FormControlLabel control={<Switch checked={isBlueSkyMode} onChange={handleChange} inputProps={{
      'aria-label': 'controlled'
    }} />} label={tr('home_staging.steps.definition.blue_sky_mode.label')} />

            <p style={{
      marginBottom: 0
    }}>
                {tr('home_staging.steps.options.strength.example')}
            </p>
            <div style={{
      display: 'flex',
      gap: '10px',
      justifyContent: 'center'
    }}>
                <div style={{
        position: 'relative',
        flex: '1'
      }}>
                    <img src='/img/jpg/homestaging/outside-bluesky-off.jpg' alt={tr('home_staging.steps.options.strength.levels.original')} style={{
          width: '100%',
          height: 'auto',
          borderRadius: '3px'
        }} />
                    <Typography variant='caption' style={{
          position: 'absolute',
          bottom: '8px',
          right: '8px',
          backgroundColor: 'white',
          padding: '2px 6px',
          borderRadius: '3px'
        }}>
                        {tr('home_staging.steps.options.strength.levels.original')}
                    </Typography>
                </div>
                <div style={{
        position: 'relative',
        flex: '1'
      }}>
                    <img src='/img/jpg/homestaging/outside-bluesky-on.jpg' alt={tr('home_staging.steps.definition.blue_sky_mode.label')} style={{
          width: '100%',
          height: 'auto',
          borderRadius: '3px'
        }} />
                    <Typography variant='caption' style={{
          position: 'absolute',
          bottom: '8px',
          right: '8px',
          backgroundColor: 'white',
          padding: '2px 6px',
          borderRadius: '3px'
        }}>
                        {tr('home_staging.steps.definition.blue_sky_mode.label')}
                    </Typography>
                </div>
            </div>
        </>;
};
export default BlueSkySelector;