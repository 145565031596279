import round from 'lodash/round';

function queryFavourites(id: string, inWorkspace: string, libraryIds: string[]) {
    const landscapeRatio = round(16 / 9, 2);
    return id
        ? `{
            homePayloadFavourites: user(id: "${id}") {
                id
                homePayloadUserFavouritesOrganization:
                    favouriteTemplates (
                        sort: "markedAsFavouriteAt:desc"
                        ${inWorkspace ? `inWorkspace: "${inWorkspace}"` : 'organizationRoot: true'}
                        first: 64
                    ) {
                        totalCount
                        edges {
                            node {
                                id
                                shortId
                                exhaustive
                                createdAt
                                name
                                level
                                consideredAsNew
                                consideredAsNewAt
                                custom
                                pictorial
                                preview (preferredRatio: ${landscapeRatio}) {
                                    src
                                }
                                thumbnail (preferredRatio: ${landscapeRatio}) {
                                    src
                                }
                                workspace {
                                    id
                                    name
                                }
                                categories {
                                    totalCount
                                    edges {
                                        node {
                                            id
                                            displayRank
                                        }
                                    }
                                }
                            }  
                        }
                    }
                homePayloadUserFavouritesLibrary:
                    favouriteTemplates (
                            sort: "markedAsFavouriteAt:desc"
                            ${libraryIds ? `inLibrary: "${libraryIds.join(' | ')}"` : ''}
                            first: 64
                        ) {
                            totalCount
                            edges {
                                node {
                                    id
                                    shortId
                                    exhaustive
                                    createdAt
                                    name
                                    level
                                    consideredAsNew
                                    consideredAsNewAt
                                    custom
                                    pictorial
                                    preview (preferredRatio: ${landscapeRatio}) {
                                        src
                                    }
                                    thumbnail (preferredRatio: ${landscapeRatio}) {
                                        src
                                    }
                                    workspace {
                                        id
                                        name
                                    }
                                    categories {
                                        totalCount
                                        edges {
                                            node {
                                                id
                                                displayRank
                                                }
                                            }
                                        }
                                    }  
                                }
                            }
                }
        }
        `
        : null;
}

const queryFavouritesMock = () => 'FrontFavourites';

const queryFrontFavouritesApi = process.env.NEXT_PUBLIC_MOCKS === 'true' ? queryFavouritesMock : queryFavourites;

export default queryFrontFavouritesApi;
