import React, { useCallback, useMemo } from 'react';
import RoomSelector from '@components/office/front-office/home/home-staging/dialog/steps/definition/RoomSelector';
import TreatmentSelector from '@components/office/front-office/home/home-staging/dialog/steps/definition/TreatmentSelector';
import { HomeStagingRoom } from '@components/office/front-office/home/home-staging/dialog/types/HomeStagingRoom';
import StyleSelector from '@components/office/front-office/home/home-staging/dialog/steps/definition/StyleSelector';
import OutRoomSelector from '@components/office/front-office/home/home-staging/dialog/steps/definition/OutRoomSelector';
import { HomeStagingStyle } from '@components/office/front-office/home/home-staging/dialog/types/HomeStagingStyle';
import HomeStagingTreatmentEnum from '@components/office/front-office/home/home-staging/dialog/enums/HomeStagingTreatmentEnum';
import HomeStagingKindEnum from '@components/office/front-office/home/home-staging/dialog/enums/HomeStagingKindEnum';
import { HomeStagingTreatment } from '@components/office/front-office/home/home-staging/dialog/types/HomeStagingTreatment';
import InfoIcon from '@mui/icons-material/Info';
type Props = {
  room: HomeStagingRoom;
  treatment: HomeStagingTreatment;
  style: HomeStagingStyle;
  outRoom: HomeStagingRoom;
  onChange: (field: string, value: any) => void;
};
const HomeStagingDefinitionStep = (props: Props) => {
  const {
    room,
    treatment,
    style,
    outRoom,
    onChange
  } = props;
  const handleRoomChange = useCallback((value: HomeStagingRoom) => onChange('room', value), [onChange]);
  const handleTreatmentChange = useCallback((value: string) => onChange('treatment', value), [onChange]);
  const handleStyleChange = useCallback((value: HomeStagingStyle) => onChange('style', value), [onChange]);
  const handleOutRoomChange = useCallback((value: HomeStagingRoom) => onChange('outRoom', value), [onChange]);
  const isStyleSelectorVisible = useCallback(() => {
    return [(HomeStagingTreatmentEnum.REDECORATE as string), (HomeStagingTreatmentEnum.FURNISH as string)].includes(treatment) && room;
  }, [treatment, room]);
  const isFillTypeSelectorVisible = useCallback(() => {
    return [(HomeStagingTreatmentEnum.FURNISH as string)].includes(treatment) && room?.kind === (HomeStagingKindEnum.INSIDE as string) && room;
  }, [treatment, room]);
  const isEmptyRoomAndRedecorate = useMemo(() => {
    return room?.name === 'EMPTY_ROOM' && treatment === (HomeStagingTreatmentEnum.REDECORATE as string);
  }, [room, treatment]);
  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '10px'
  }}>
            <RoomSelector room={room} onChange={handleRoomChange} />
            <TreatmentSelector room={room} treatment={treatment} onChange={handleTreatmentChange} />
            {isEmptyRoomAndRedecorate && <div style={{
      display: 'flex',
      alignItems: 'center',
      gap: '8px'
    }}>
                    <InfoIcon /> <span>{tr('home_staging.steps.definition.treatment.empty_room_tooltip')}</span>
                </div>}
            {isStyleSelectorVisible() && <StyleSelector room={room} style={style} onChange={handleStyleChange} />}
            {isFillTypeSelectorVisible() && <OutRoomSelector room={room} outRoom={outRoom} onChange={handleOutRoomChange} />}
        </div>;
};
export default HomeStagingDefinitionStep;