import React, { useCallback, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import HomeStaging from '@components/office/front-office/home/home-staging/dialog/configuration/HomeStaging';
import { HomeStagingRoom } from '@components/office/front-office/home/home-staging/dialog/types/HomeStagingRoom';
import { HomeStagingKeep } from '@components/office/front-office/home/home-staging/dialog/types/HomeStagingKeep';
type Props = {
  room: HomeStagingRoom;
  onChange: (value: string[]) => void;
  treatment: string;
};
const KeepSelector = (props: Props) => {
  const {
    room,
    onChange,
    treatment
  } = props;
  const [selectedKeeps, setSelectedKeeps] = useState<HomeStagingKeep[]>([]);
  const [items, setItems] = useState<HomeStagingKeep[]>([]);
  const disabledKeeps = ['FLOOR', 'WALLS'];
  useEffect(() => {
    HomeStaging.getKeeps(room).then(response => {
      setItems(response);
    });
  }, [room]);
  const handleChange = useCallback((name: string) => {
    if (treatment === 'FURNISH' && disabledKeeps.includes(name)) return;
    const selectedItem = items.find(item => item.name === name);
    if (!selectedItem) return;
    const isSelected = selectedKeeps.some(item => item.name === name);
    const newSelectedKeeps = isSelected ? selectedKeeps.filter(item => item.name !== name) : [...selectedKeeps, selectedItem];
    setSelectedKeeps(newSelectedKeeps);
    onChange(newSelectedKeeps.map(item => item.name));
  }, [onChange, items, selectedKeeps, disabledKeeps, treatment]);
  const isItemSelected = useCallback(name => {
    return selectedKeeps.some(item => item.name === name);
  }, [selectedKeeps]);
  return <>
            <Typography variant='h6'>{tr('home_staging.steps.options.keep.title')}</Typography>
            <div className='mosaic-container' style={{
      display: 'flex',
      flexWrap: 'wrap',
      gap: '10px',
      width: '100%',
      height: '150px',
      overflowY: 'auto',
      padding: '6px'
    }}>
                {items.map(({
        name,
        label
      }, index) => {
        const isDisabled = treatment === 'FURNISH' && disabledKeeps.includes(name);
        if (isDisabled) return null;
        return <div key={index} onClick={() => handleChange(name)}>
                            <div style={{
            position: 'relative',
            display: 'inline-block'
          }}>
                                <div style={{
              position: 'relative',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: isItemSelected(name) ? '#3294A5' : '#fff',
              color: isItemSelected(name) ? '#fff' : '#808080',
              borderRadius: '50px',
              border: '1px solid #c0c0c0',
              padding: '8px 16px',
              cursor: 'pointer',
              width: 'auto',
              textAlign: 'center',
              fontWeight: 'bold',
              transition: 'all 0.3s ease'
            }}>
                                    {label} {isItemSelected(name)}
                                </div>
                            </div>
                        </div>;
      })}
            </div>
        </>;
};
export default KeepSelector;