import React, { useCallback, useEffect } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import useTheme from '@mui/system/useTheme';
import { useMediaQuery } from '@mui/material';
import { useIsMobile } from '@components/bridge/bridge/music-library/layout';
import { isEnvProd } from '@utils/getEnv';
import { ImageToVideoFormData } from '@components/office/front-office/home/image-to-video/dialog/types/ImageToVideoFormData';
import ImageToVideo from '@components/office/front-office/home/image-to-video/dialog/configuration/ImageToVideo';
import { mutate } from 'swr';
import queryAppUserApi from '@queries/api/bridge/queryAppUser';
import User from '@utils/User';
import Tooltip from '@mui/material/Tooltip';
type Props = {
  selectedStepNumber: number;
  stepCounter: number;
  formData: ImageToVideoFormData;
  isLoading: boolean;
  generationSrc: string;
  onPrevious: () => void;
  onNext: () => void;
  onSubmit: () => void;
  onDownload: () => void;
  remainingGenerations: number;
};
const ImageToVideoDialogAction = (props: Props) => {
  const {
    stepCounter,
    selectedStepNumber,
    formData,
    isLoading,
    generationSrc,
    onPrevious,
    onNext,
    onSubmit,
    onDownload,
    remainingGenerations
  } = props;
  const {
    data: me
  } = User.useLoadMe();
  const retryTooltip = tr('image_to_video.retry_tooltip', {
    remaining: remainingGenerations
  });
  const theme = useTheme();
  const isMobile = useIsMobile();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const isFirstStep = useCallback(() => selectedStepNumber === 0, [selectedStepNumber]);
  const isLastStep = useCallback(() => selectedStepNumber === stepCounter - 1, [stepCounter, selectedStepNumber]);
  const isBeforeLast = useCallback(() => selectedStepNumber === stepCounter - 2, [stepCounter, selectedStepNumber]);
  const isNextStepButtonEnabled = useCallback(() => ImageToVideo.isValideStep(selectedStepNumber, formData), [formData, selectedStepNumber]);
  const handlePrevious = useCallback(() => onPrevious(), [onPrevious]);
  const handleNext = useCallback(() => onNext(), [onNext]);
  const handleSubmit = useCallback(() => {
    onSubmit();
    mutate(queryAppUserApi(me.id));
  }, [onSubmit]);
  const handleDownload = useCallback(() => onDownload(), [onDownload]);
  const isBackButtonVisible = useCallback(() => {
    return !isFirstStep() && !isLoading;
  }, [isFirstStep, isLoading]);
  useEffect(() => {
    if (isLastStep()) {
      handleSubmit();
    }
  }, [isLastStep, handleSubmit]);
  return <DialogActions style={{
    paddingRight: isEnvProd && (isMobile || isTablet) ? '80px' : undefined
  }}>
            <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '10px'
    }}>
                {isBeforeLast() && <div>
                        <strong>{tr('image_to_video.submit_info')}</strong>
                    </div>}
                {isLastStep() && <div>
                        <strong>{tr('image_to_video.submit_retry_info')}</strong>
                    </div>}
                <div style={{
        display: 'flex',
        justifyContent: 'flex-end'
      }}>
                    <div>
                        {isBackButtonVisible() && <Button onClick={handlePrevious} disabled={remainingGenerations === 0}>
                                {tr('image_to_video.actions.previous')}
                            </Button>}
                        {!isLastStep() && <Button onClick={handleNext} disabled={!isNextStepButtonEnabled() || remainingGenerations === 0}>
                                {isBeforeLast() ? tr('image_to_video.actions.submit') : tr('image_to_video.actions.next')}
                            </Button>}
                    </div>

                    {isLastStep() && <>
                            <Tooltip title={retryTooltip}>
                                <span>
                                    <Button onClick={handleSubmit} disabled={isLoading || remainingGenerations === 0}>
                                        {tr('image_to_video.actions.retry')}
                                    </Button>
                                </span>
                            </Tooltip>
                            <Button variant='contained' onClick={handleDownload} disabled={!generationSrc}>
                                {tr('image_to_video.actions.download')}
                            </Button>
                        </>}
                </div>
            </div>
        </DialogActions>;
};
export default ImageToVideoDialogAction;