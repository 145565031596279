import React, { useCallback, useState } from 'react';
import { Fade, useMediaQuery } from '@mui/material';
import useTheme from '@mui/system/useTheme';
import DanimModal from '@components/bridge/bridge/DanimModal';
import Button from '@mui/material/Button';
import ModalRouterChangeConfirm from '@components/office/front-office/home/home-staging/dialog/confirm/ModalRouterChangeConfirm';
import ImageToVideo from '@components/office/front-office/home/image-to-video/dialog/configuration/ImageToVideo';
import { CustomDialogTitle } from '@components/bridge/bridge/DraggableHelperDialog.styles';
import { ImageToVideoStepConfiguration } from '@components/office/front-office/home/image-to-video/dialog/types/ImageToVideoStepConfiguration';
import ImageToVideoDialogContent from '@components/office/front-office/home/image-to-video/dialog/ImageToVideoDialogContent';
import { ImageToVideoFormData } from '@components/office/front-office/home/image-to-video/dialog/types/ImageToVideoFormData';
import ImageToVideoDialogAction from '@components/office/front-office/home/image-to-video/dialog/ImageToVideoDialogAction';
import DraggableHelperDialog from '@components/bridge/bridge/DraggableHelperDialog';
import Lightbulb from '@mui/icons-material/Lightbulb';
import ModalConfirm from '@components/office/front-office/home/image-to-video/dialog/confirm/ModalConfirm';
type Props = {
  open: boolean;
  onClose: () => void;
  remainingGenerations: number;
};
const ImageToVideoDialog = (props: Props) => {
  const {
    open,
    onClose,
    remainingGenerations
  } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [isOpenModalPreviousConfirm, setIsOpenModalPreviousConfirm] = useState<boolean>(false);
  const [isOpenModalCloseConfirm, setIsOpenModalCloseConfirm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isClosable, setIsClosable] = useState<boolean>(true);
  const [activeStep, setActiveStep] = useState(0);
  const stepperConfiguration: ImageToVideoStepConfiguration[] = ImageToVideo.getStepperConfiguration();
  const [formData, setFormData] = useState<ImageToVideoFormData>(ImageToVideo.getInitialFormData());
  const [generationSrc, setGenerationSrc] = useState<string>(null);
  const handleSubmit = useCallback(async () => {
    setIsClosable(false);
    await ImageToVideo.postGenerateImageToVideo(formData, setIsLoading, setGenerationSrc);
  }, [formData]);
  const handleDownload = useCallback(async () => {
    setIsClosable(true);
    await ImageToVideo.downloadImage(generationSrc);
  }, [generationSrc]);
  const handleClose = useCallback(() => {
    if (isClosable) {
      resetModal();
      onClose();
    } else {
      setIsOpenModalCloseConfirm(true);
    }
  }, [isClosable, onClose]);
  const handleForceClose = useCallback(() => {
    resetModal();
    onClose();
  }, [onClose]);
  const handlePrevious = useCallback(() => {
    if (isClosable) {
      setActiveStep(current => current - 1);
    } else {
      setIsOpenModalPreviousConfirm(true);
    }
  }, [isClosable]);
  const handlePreviousConfirm = () => {
    setActiveStep(current => current - 1);
    setIsClosable(true);
    setIsOpenModalPreviousConfirm(false);
  };
  const resetModal = () => {
    setActiveStep(0);
    setFormData(ImageToVideo.getInitialFormData());
    setIsClosable(true);
    setIsLoading(false);
    setGenerationSrc(null);
    setIsOpenModalCloseConfirm(false);
  };
  const handleChangeFormData = useCallback((key: string, value: string | any) => {
    setFormData(currentState => {
      return {
        ...currentState,
        [key]: value
      };
    });
  }, []);
  return <>
            <DanimModal handleClose={() => {}} open={open} maxWidth='md' fullWidth fullScreen={fullScreen} TransitionComponent={Fade} TransitionProps={{
      timeout: 500
    }} disableEscapeKeyDown>
                <ModalRouterChangeConfirm isLoading={isLoading} />
                <ModalConfirm isOpen={isOpenModalCloseConfirm} onCancel={() => setIsOpenModalCloseConfirm(false)} onConfirm={handleForceClose} isLoading={isLoading} />
                <ModalConfirm isOpen={isOpenModalPreviousConfirm} onCancel={() => setIsOpenModalPreviousConfirm(false)} onConfirm={handlePreviousConfirm} isLoading={isLoading} />
                <CustomDialogTitle style={{
        display: 'flex',
        justifyContent: 'space-between'
      }}>
                    <div>{tr('image_to_video.title')}</div>
                    <div style={{
          display: 'flex',
          gap: '12px'
        }}>
                        <DraggableHelperDialog dialogTitle={tr('image_to_video.title')} draggableId='draggable-custom-image2video' url='https://www.youtube.com/embed/GMDYWj_c9jI' ctaIcon={<Lightbulb />} chipSize='medium' />
                        <Button onClick={handleClose}>X</Button>
                    </div>
                </CustomDialogTitle>

                <ImageToVideoDialogContent stepperConfiguration={stepperConfiguration} selectedStepNumber={activeStep} formData={formData} onChangeFormData={handleChangeFormData} isLoading={isLoading} generationSrc={generationSrc} />

                <ImageToVideoDialogAction selectedStepNumber={activeStep} stepCounter={stepperConfiguration.length} formData={formData} isLoading={isLoading} generationSrc={generationSrc} onPrevious={handlePrevious} onNext={() => setActiveStep(current => current + 1)} onSubmit={handleSubmit} onDownload={handleDownload} remainingGenerations={remainingGenerations} />
            </DanimModal>
        </>;
};
export default ImageToVideoDialog;