import { styled } from '@mui/system';
import MovieCreationIcon from '@mui/icons-material/MovieCreation';
import { Skeleton } from '@mui/material';
export const CardVideo = styled('div')`
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 25px -5px, rgba(0, 0, 0, 0.04) 0px 5px 10px -5px;
    background-color: #eeeeee;
    display: flex;
    flex-direction: column;
    animation: fadeIn 0.25s ease-in-out;
    width: calc(16 / 9 * 12rem);
    border-radius: 1rem;
    transition: all 0.1s linear;
    border: 1px solid transparent;
    position: relative;
    overscroll-behavior-x: none;
    &:hover {
        border: ${({
  theme
}) => `1px solid ${theme.palette.primary.main}`};
        box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 25px -5px, rgba(0, 0, 0, 0.04) 0px 5px 10px -5px;
        cursor: pointer;
    }
    @media (max-width: 600px) {
        width: calc(16 / 9 * 10.9rem);
        margin: 0 auto;
    }
`;
export const CardVideoBody = styled('div')`
    position: relative;
    transition: all 0.2s ease-in-out;
    height: calc(16 / 9 * 6.7rem);

    img {
        width: 100%;
        height: 100%;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
    }

    video {
        height: 100%;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
    }

    .absolute {
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
    }

    @media (max-width: 600px) {
        height: calc(16 / 9 * 6.15rem);
    }
`;
export const CardVideoFooter = styled('div')`
    position: relative;
    display: flex;
    padding: 1rem 0.75rem;
    flex-grow: 1;
    align-items: center;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    background-color: ${({
  theme
}) => theme.palette.background.default};
    flex-direction: column;
`;
export const CardVideoIcons = styled('div')`
    display: flex;
    flex-direction: row;
    top: 1rem;
    left: 1rem;
`;
export const MobileFavourite = styled('div')`
    position: absolute;
    top: -1rem;
    right: 0.8rem;
`;
export const ChipContainer = styled('div')`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: absolute;
    user-select: none;
    top: -0.5rem;
    left: -0.75rem;
    gap: 0.5rem;
    z-index: 11;
`;

// Migration to typescript

// interface IconLevelProps {
//     index: number;
//     video: {
//         level: number;
//     }
// }

// const IconLevel = styled(WhatshotIcon)<IconLevelProps>`
//     opacity: ${({ index, video }) => (index + 1 > video.level ? 0.4 : 1)};
// `;

export const StyledSkeleton = styled(Skeleton)`
    display: inline-block;
    border-radius: 1rem;
    min-width: calc(16 / 9 * 12rem);
    min-height: calc(16 / 9 * 9.7rem);
    @media (max-width: 600px) {
        min-width: calc(16 / 9 * 10.9rem);
        min-height: calc(16 / 9 * 8rem);
    }
`;