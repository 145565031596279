import React, { useCallback } from 'react';
import HomeStagingTreatmentEnum from '@components/office/front-office/home/home-staging/dialog/enums/HomeStagingTreatmentEnum';
import HomeStagingKindEnum from '@components/office/front-office/home/home-staging/dialog/enums/HomeStagingKindEnum';
import BlueSkySelector from '@components/office/front-office/home/home-staging/dialog/steps/definition/BlueSkySelector';
import { HomeStagingRoom } from '@components/office/front-office/home/home-staging/dialog/types/HomeStagingRoom';
import KeepSelector from '@components/office/front-office/home/home-staging/dialog/steps/options/KeepSelector';
import { HomeStagingStyle } from '@components/office/front-office/home/home-staging/dialog/types/HomeStagingStyle';
import StrengthSelector from '@components/office/front-office/home/home-staging/dialog/steps/options/StrengthSelector';
import { Typography } from '@mui/material';
type Props = {
  room: HomeStagingRoom;
  treatment: string;
  blueSkyMode: boolean;
  onChange: (field: string, value: any) => void;
  strength?: number;
  style: HomeStagingStyle;
};
const HomeStagingOptionsStep = (props: Props) => {
  const {
    room,
    treatment,
    blueSkyMode,
    strength,
    onChange,
    style
  } = props;
  const handleBlueSkyModeChange = useCallback((value: boolean) => onChange('blueSkyMode', value), [onChange]);
  const handleStrengthChange = (event: React.MouseEvent<HTMLElement>, newStrength: number | null) => {
    if (newStrength !== null) {
      onChange('strength', newStrength);
    }
  };
  const handleKeepChange = useCallback((value: string[]) => onChange('keptElements', value), [onChange]);
  const isBlueSkySelectorVisible = useCallback(() => {
    return [(HomeStagingTreatmentEnum.ENHANCE as string)].includes(treatment) && room?.kind === (HomeStagingKindEnum.OUTSIDE as string) && room;
  }, [treatment, room]);
  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '10px'
  }}>
            {treatment === 'REDECORATE' || treatment === 'FURNISH' ? <>
                    <StrengthSelector room={room} strength={strength} handleStrengthChange={handleStrengthChange} />
                    <KeepSelector room={room} onChange={handleKeepChange} treatment={treatment} />
                </> : isBlueSkySelectorVisible() ? <BlueSkySelector blueSkyMode={blueSkyMode} onChange={handleBlueSkyModeChange} /> : <Typography variant='body1'>{tr('home_staging.steps.options.unavailable')}</Typography>}
        </div>;
};
export default HomeStagingOptionsStep;