import React from 'react';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import ImageToVideoResolutionEnum from '@components/office/front-office/home/image-to-video/dialog/enums/ImageToVideoResolutionEnum';
type ResolutionOptionProps = {
  resolution: ImageToVideoResolutionEnum;
  isSelected: boolean;
  onClick: () => void;
  label: string;
  width: string;
  height: string;
};
const ResolutionOption: React.FC<ResolutionOptionProps> = ({
  resolution,
  isSelected,
  onClick,
  label,
  width,
  height
}) => {
  return <label style={{
    display: 'flex',
    gap: '8px',
    alignItems: 'center'
  }}>
            <input type='radio' name='resolution' value={resolution} checked={isSelected} onChange={() => onClick()} />
            <div style={{
      display: 'flex',
      alignItems: 'center',
      width,
      height,
      border: '2px solid #545454',
      borderRadius: '6px',
      justifyContent: 'center'
    }}>
                <PlayArrowRoundedIcon style={{
        color: '#545454'
      }} fontSize='large' />
            </div>
            {label}
        </label>;
};
export default ResolutionOption;