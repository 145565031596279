import { Swiper } from 'swiper/react';
import { styled } from '@mui/system';
type SwiperButtonProps = {
  isMobile: boolean;
};
export const StyledSwiper = styled(Swiper)`
    min-height: 18rem;
    width: 100%;
    height: 100%;
    overflow: visible !important;
    position: unset !important;

    @media (max-width: 600px) {
        min-height: 18rem;
        margin-left: unset !important;
    }
`;
export const SwipeButtonPrev = styled('div')<SwiperButtonProps>`
    display: flex;
    align-items: center;
    position: absolute;
    top: ${({
  isMobile
}) => isMobile ? '0' : '0'};
    left: ${({
  isMobile
}) => isMobile ? '1rem' : 'unset'};
    right: ${({
  isMobile
}) => isMobile ? 'unset' : '4.7rem'};
    bottom: ${({
  isMobile
}) => isMobile ? '0' : 'unset'};
    &:hover {
        cursor: pointer;
    }
    &.swiper-button-disabled > button {
        color: rgba(145, 158, 171, 0.8);
        box-shadow: none;
        background-color: rgba(145, 158, 171, 0.24);
        cursor: default;
    }
`;
export const SwipeButtonNext = styled('div')<SwiperButtonProps>`
    display: flex;
    align-items: center;
    position: absolute;
    top: ${({
  isMobile
}) => isMobile ? '0' : '0'};
    right: ${({
  isMobile
}) => isMobile ? '1rem' : '2rem'};
    bottom: ${({
  isMobile
}) => isMobile ? '0' : 'unset'};
    &:hover {
        cursor: pointer;
    }
    &.swiper-button-disabled > button {
        color: rgba(145, 158, 171, 0.8);
        box-shadow: none;
        background-color: rgba(145, 158, 171, 0.24);
        cursor: default;
    }
`;
export const SkeletonContainer = styled('div')`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
    width: 100%;
`;