import React, { useCallback, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import HomeStaging from '@components/office/front-office/home/home-staging/dialog/configuration/HomeStaging';
import { HomeStagingRoom } from '@components/office/front-office/home/home-staging/dialog/types/HomeStagingRoom';
import homeStagingTreatmentEnum from '@components/office/front-office/home/home-staging/dialog/enums/HomeStagingTreatmentEnum';
import homeStagingKindEnum from '@components/office/front-office/home/home-staging/dialog/enums/HomeStagingKindEnum';
type Props = {
  room: HomeStagingRoom;
  treatment: string;
  onChange: (value: string) => void;
};
const TreatmentSelector = (props: Props) => {
  const {
    room,
    treatment,
    onChange
  } = props;
  const [selectedTreatment, setSelectedTreatment] = useState<string>(treatment || '');
  useEffect(() => {
    setSelectedTreatment(treatment);
  }, [treatment]);
  const handleChange = useCallback((event: React.MouseEvent<HTMLElement>, value: string | null) => {
    if (value) {
      setSelectedTreatment(value);
      onChange(value);
    }
  }, [onChange]);
  const isDisabled = useCallback(name => {
    return name === homeStagingTreatmentEnum.FURNISH && room && room?.kind === homeStagingKindEnum.OUTSIDE;
  }, [room]);
  return <>
            <Typography variant='h6'>{tr('home_staging.steps.definition.treatment.title')}</Typography>
            <ToggleButtonGroup exclusive value={selectedTreatment} onChange={handleChange} fullWidth>
                {HomeStaging.getTreatmentTypes().map(({
        name,
        label
      }, index) => <ToggleButton key={index} value={name} disabled={isDisabled(name)}>
                        {label}
                    </ToggleButton>)}
            </ToggleButtonGroup>
        </>;
};
export default TreatmentSelector;