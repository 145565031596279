import React, { useState, useCallback, useLayoutEffect } from 'react';

interface WindowScrollState {
    x: number | null;
    y: number | null;
}

function useWindowScroll(): [WindowScrollState, (...args: any[]) => void] {
    const [state, setState] = useState<WindowScrollState>({
        x: null,
        y: null
    });

    const scrollTo = useCallback((...args: any[]) => {
        if (typeof args[0] === 'object') {
            const options = args[0];
            window.scrollTo(options);
        } else if (typeof args[0] === 'number' && typeof args[1] === 'number') {
            const x = args[0];
            const y = args[1];
            window.scrollTo(x, y);
        } else {
            throw new Error(
                `Invalid arguments passed to scrollTo. See here for more info. https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo`
            );
        }
    }, []);

    useLayoutEffect(() => {
        const handleScroll = () => {
            setState({ x: window.pageXOffset, y: window.pageYOffset });
        };

        handleScroll();
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return [state, scrollTo];
}

export default useWindowScroll;
