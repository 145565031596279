import React, { useEffect, useMemo } from 'react';
import Factory from '@components/bridge/bridge/immutable/factory';
import queryAppUserApi from '@queries/api/bridge/queryAppUser';
import { useRouter } from 'next/router';
import useSharedState from '@hooks/useSharedState';
import useSWR from 'swr';
import { apiSwrRequest, logout } from '@services/http/client';
import queryWorkspaceApi from '@queries/api/bridge/queryWorkspace';
import AppUser from '@components/bridge/bridge/immutable/app-user';
import BridgeUserService from '@services/bridge/user/BridgeUserService';
import Category from '@components/office/front-office/home/Category';
import { styled } from '@mui/system';
import TemplateLibrary from '@components/office/front-office/template/TemplateLibrary';
import queryFrontCategoriesApi from '@queries/api/office/front-office/category/queryCategories';
import Loader from '@components/bridge/bridge/Loader';
import queryFrontFavouritesApi from '@queries/api/office/front-office/bridge/queryFavourites';
import queryFrontExhaustiveApi from '@queries/api/office/front-office/bridge/queryExhaustive';
import Delayed from '@components/bridge/bridge/Delayed';
import MenuButtons from '@components/office/front-office/home/MenuButtons';
import User from '@utils/User';
import Workspace from '@utils/Workspace';
import i18n from '../../i18n';
export default function Index() {
  const router = useRouter();
  const workspaceID = Workspace.getSelectedWorkspaceId();
  const [meta, setMeta] = useSharedState('meta');
  const {
    data: me
  } = User.useLoadMe();
  const {
    data: appuser
  } = useSWR(() => me?.id ? queryAppUserApi(me?.id, workspaceID) : null, BridgeUserService.getUser);
  const {
    data: exhaustive
  } = useSWR(() => appuser?.organization?.libraryIds ? queryFrontExhaustiveApi(workspaceID, appuser?.organization?.libraryIds) : null, apiSwrRequest);
  const {
    data: favourites
  } = useSWR(() => queryFrontFavouritesApi(appuser.id, workspaceID, appuser?.organization?.libraryIds), data => apiSwrRequest(data, 'homePayloadFavourites'));
  const {
    data: categories
  } = useSWR(() => !workspaceID && appuser?.organization?.libraryIds ? queryFrontCategoriesApi(me.id, (Factory.create('AppUser', appuser, false) as AppUser).isOrganizationDirector() ? 'NOT_STRICTLY' : 'NOT_BROADLY', null, appuser?.organization?.libraryIds) : null, data => apiSwrRequest(data, 'homePayloadCategories'));
  const {
    data: workspace
  } = useSWR(() => appuser?.organization?.libraryIds ? queryWorkspaceApi(workspaceID, (Factory.create('AppUser', appuser, false) as AppUser).isOrganizationDirector() ? 'NOT_STRICTLY' : 'NOT_BROADLY', !!workspaceID, appuser?.organization?.libraryIds) : null, data => apiSwrRequest(data, 'bridgeWorkspace'));
  // User favourites
  const userFavourites = useMemo(() => [...(favourites?.homePayloadUserFavouritesOrganization?.edges?.map(({
    node
  }) => node) ?? []), ...(favourites?.homePayloadUserFavouritesLibrary?.edges?.map(({
    node
  }) => node) ?? [])], [favourites]);

  // Exhaustive templates
  const exhaustiveTemplates = useMemo(() => [...(exhaustive?.organizationTemplates?.edges || []), ...(exhaustive?.libraryTemplates?.edges || [])], [exhaustive]);

  // Categories array
  const categoriesArray = useMemo(() => workspace && workspace.id ? workspace.accessibleCategories?.edges : categories?.organization.accessibleCategories.edges, [workspace, categories]);
  const currentWorkspace = useMemo(() => appuser?.organization.workspaces.totalCount >= 1 ? appuser.organization.workspaces.edges.find(workspace => workspace.node.id === workspaceID)?.node : null, [appuser, workspaceID]);
  useEffect(() => {
    if (appuser === undefined || !router) return;
    const appUser = Factory.create('AppUser', appuser, false);
    TemplateLibrary.potentiallyBlockUnlicensedUser(appUser, router).catch(console.error);
    TemplateLibrary.potentiallyBlockPausedUser(appUser, router).catch(console.error);
  }, [appuser]);
  useEffect(() => {
    setMeta({
      ...meta,
      title: tr('front.library.title')
    });
  }, [meta]);
  useEffect(() => {
    i18n.defaultLang =
    // @ts-ignore
    (appuser?.account?.locale || appuser?.organization?.locale || localStorage.getItem('defaultLang') || 'fr').toLowerCase();
  }, [appuser]);
  if (me === undefined) return <div className='innerLoader'>
                <Loader />
            </div>;
  if (!me?.id) {
    logout().catch(console.error);
    return null;
  }
  if (exhaustive === undefined) return <div className='innerLoader'>
                <Loader />
            </div>;
  const exhaustiveTemplate = exhaustiveTemplates[0]?.node || null;
  return <IndexContainer>
            <MenuButtons appuser={appuser} exhaustiveTemplate={exhaustiveTemplate} router={router} />
            {userFavourites.length > 0 && <Category id='favourites' title={tr('front.category.favourites.title')} appuser={Factory.create('AppUser', appuser, false)} workspace={currentWorkspace} description={tr('front.category.favourites.description')} totalCount={userFavourites.length} libraryIds={appuser?.organization?.libraryIds || null} favourites={userFavourites} />}
            {categoriesArray?.map(({
      node: category
    }, index) => {
      const totalCount = (category.templatesOrganization?.totalCount || 0) + (category.templatesLibrary?.totalCount || 0);
      if (totalCount === 0) return null;
      if (!category.id) return;
      return <Delayed key={category.id} waitBeforeShow={index * 100}>
                        <Category key={category.id} id={category.id} title={category?.publicName || ''} appuser={Factory.create('AppUser', appuser, false)} workspace={currentWorkspace} description={category?.description || null} totalCount={totalCount} libraryIds={appuser?.organization?.libraryIds || null} favourites={userFavourites} />
                    </Delayed>;
    })}
        </IndexContainer>;
}
export const IndexContainer = styled('div')`
    display: flex;
    flex-direction: column;
    padding: 2rem;
    gap: 1rem;
    width: 100%;
    @media (max-width: 600px) {
        gap: 0.5rem;
    }
`;