import round from 'lodash/round';

function queryCategory(
    id: string | string[],
    needInformations: boolean = false,
    privacyType: string = 'NOT_BROADLY',
    inWorkspace: string = null,
    inLibrary: string[] = null
) {
    const landscapeRatio = round(16 / 9, 2);
    return id
        ? `{
            categoryPayload:
                category (id: "${id}") {
                    id
                    ${
                        needInformations
                            ? `
                                publicName
                                description
                                displayRank
                                `
                            : ''
                    }
            }
            categoryPayloadTemplates:
                category (id: "${id}") {
                    id
                    templates(
                        first: 64
                        private: ${privacyType}
                        ${inWorkspace ? `inWorkspace: "${inWorkspace}"` : 'organizationRoot: true'}
                        ) {
                        totalCount
                        edges {
                            node {
                                id
                                shortId
                                exhaustive
                                createdAt
                                name
                                level
                                consideredAsNew
                                consideredAsNewAt
                                custom
                                pictorial
                                preview (preferredRatio: ${landscapeRatio}) {
                                    src
                                }
                                thumbnail (preferredRatio: ${landscapeRatio}) {
                                    src
                                }
                                workspace {
                                    id
                                    name
                                }
                            }
                        }
                   }
                }
                categoryPayloadLibrary:
                    category (id: "${id}") {
                        id
                        templates(
                            first: 64
                            private: ${privacyType}
                            ${inLibrary ? `inLibrary: "${inLibrary.join(' | ')}"` : ''}
                            ) {
                            totalCount
                            edges {
                                node {
                                    id
                                    shortId
                                    exhaustive
                                    createdAt
                                    name
                                    level
                                    consideredAsNew
                                    consideredAsNewAt
                                    custom
                                    pictorial
                                    preview (preferredRatio: ${landscapeRatio}) {
                                        src
                                    }
                                    thumbnail (preferredRatio: ${landscapeRatio}) {
                                        src
                                    }
                                    workspace {
                                        id
                                        name
                                    }
                                }
                            }
                       }
                    }
            }
        `
        : null;
}

const queryCategoryMock = () => 'FrontCategory';

const queryFrontCategoryApi = process.env.NEXT_PUBLIC_MOCKS === 'true' ? queryCategoryMock : queryCategory;

export default queryFrontCategoryApi;
