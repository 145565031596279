import React, { useCallback, useState } from 'react';
import HomeStagingDialog from '@components/office/front-office/home/home-staging/HomeStagingDialog';
import AnimatedButton from '@components/office/front-office/home/AnimatedButton';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
const HomeStagingButton = () => {
  const [openHomeStaging, setOpenHomeStaging] = useState<boolean>(false);
  const handleOpenHomeStaging = useCallback(() => setOpenHomeStaging(true), [setOpenHomeStaging]);
  const handleCloseHomeStaging = useCallback(() => setOpenHomeStaging(false), [setOpenHomeStaging]);
  return <>
            <HomeStagingDialog open={openHomeStaging} onClose={handleCloseHomeStaging} />
            <AnimatedButton handleClick={handleOpenHomeStaging} label='Home Staging' icon={<PhotoCameraIcon />} tooltip={tr('home_staging.tooltip')} hideTooltipOnMobile />
        </>;
};
export default HomeStagingButton;