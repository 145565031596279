import { Variants } from 'framer-motion';
const dialogAnim: Variants = {
  init: {
    opacity: 0,
    y: -100,
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  open: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 0.7
    }
  },
  closed: {
    opacity: 0,
    y: -40,
    transition: {
      type: 'spring',
      bounce: 0,
      duration: 0.7
    }
  }
};
export default dialogAnim;