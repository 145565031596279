import React from 'react';
import { Dialog, DialogProps, PaperProps } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { motion } from 'framer-motion';
import dialogAnim from '@components/bridge/bridge/DanimModal.anim';
interface DanimModalProps extends DialogProps {
  open: boolean;
  handleClose: () => void;
  animate?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  fullWidth?: boolean;
  fullScreen?: boolean;
  hideBackdrop?: boolean;
  disableEscapeKeyDown?: boolean;
  onEscapeKeyDown?: () => void;
  PaperProps?: Partial<PaperProps>;
  TransitionComponent?: React.ComponentType<TransitionProps>;
  TransitionProps?: TransitionProps;
  children: React.ReactNode;
}
export default function DanimModal({
  open,
  handleClose,
  maxWidth = 'sm',
  animate = true,
  fullWidth = true,
  fullScreen = false,
  hideBackdrop = false,
  disableEscapeKeyDown = false,
  PaperProps,
  TransitionComponent,
  TransitionProps,
  children,
  ...props
}: DanimModalProps) {
  return <Dialog open={open} onClose={handleClose} maxWidth={maxWidth} fullWidth={fullWidth} fullScreen={fullScreen} hideBackdrop={hideBackdrop} disableEscapeKeyDown={disableEscapeKeyDown} PaperProps={PaperProps} TransitionComponent={TransitionComponent} TransitionProps={TransitionProps} {...props}>
            {animate ? <motion.div initial='init' animate={open ? 'open' : 'closed'} variants={dialogAnim}>
                    {children}
                </motion.div> : children}
        </Dialog>;
}