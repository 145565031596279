import React, { useCallback, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import HomeStaging from '@components/office/front-office/home/home-staging/dialog/configuration/HomeStaging';
import { HomeStagingRoom } from '@components/office/front-office/home/home-staging/dialog/types/HomeStagingRoom';
import WaitingSelect from '@components/office/front-office/home/home-staging/dialog/steps/WaitingSelect';
type Props = {
  room: HomeStagingRoom;
  onChange: (value: HomeStagingRoom) => void;
};
const RoomSelector = (props: Props) => {
  const {
    room,
    onChange
  } = props;
  const [selectedRoom, setSelectedRoom] = useState<HomeStagingRoom>(room);
  const [items, setItems] = useState<HomeStagingRoom[]>([]);
  useEffect(() => {
    HomeStaging.getRooms().then(response => {
      setItems(response);
    });
  }, []);
  const handleChange = useCallback((event: SelectChangeEvent) => {
    const room: HomeStagingRoom = items.find(item => item.name === event.target.value);
    setSelectedRoom(room);
    onChange(room);
  }, [onChange, items]);
  return <>
            <Typography variant='h6'>{tr('home_staging.steps.definition.room.title')}</Typography>
            <FormControl fullWidth>
                <InputLabel id='select-data-type-label'>{tr('home_staging.steps.definition.room.placeholder')}</InputLabel>
                {items && items.length > 0 && <Select id='select-data-type' labelId='select-data-type-label' value={selectedRoom?.name || ''} label={tr('home_staging.steps.definition.room.placeholder')} onChange={handleChange} MenuProps={{
        style: {
          maxHeight: 500
        }
      }}>
                        {items.map(({
          name,
          label
        }, index) => <MenuItem key={index} value={name}>
                                {label}
                            </MenuItem>)}
                    </Select>}
                {items && items.length === 0 && <WaitingSelect label={tr('home_staging.steps.definition.room.select_label')} />}
            </FormControl>
        </>;
};
export default RoomSelector;