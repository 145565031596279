import React, { useCallback, useState } from 'react';
import Typography from '@mui/material/Typography';
import dynamic from 'next/dynamic';
import { serialize } from 'object-to-formdata';
import { axios } from '@services/http/client';
import { getAbsoluteUrl } from '@utils/filePath';
type Props = {
  file: any;
  onChange: (file: any) => void;
};
const DanimUploader = dynamic(() => import('@components/bridge/bridge/Uploader'), {
  ssr: false
});
const HomeStagingUploadStep = (props: Props) => {
  const {
    file,
    onChange
  } = props;
  const [src, setSrc] = useState(file?.src || null);
  const [progress, setProgress] = useState<number>(100);
  const handleChange = useCallback(async file => {
    if (!file) return;
    setProgress(0);
    const options = {
      method: 'POST',
      url: gen('upload/me'),
      data: serialize({
        file
      }, {
        indices: true
      }),
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent: ProgressEvent) => {
        const percentCompleted = Math.round(progressEvent.loaded * 100 / (progressEvent.total || 1));
        setProgress(percentCompleted);
      },
      refreshTokenJustBefore: true
    };
    await axios(options).then(data => {
      if (data.success) {
        onChange(data.data.file);
        setSrc(data.data.file.src);
      }
    }).catch(error => {
      console.error(error);
    });
  }, [onChange]);
  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
    padding: '10px'
  }}>
            <Typography variant='h6'>{tr('home_staging.steps.upload.title')}</Typography>
            <div style={{
      width: '377px'
    }}>
                <DanimUploader src={getAbsoluteUrl(src)} fileType={['image']} onChange={file => handleChange(file)} style={{
        width: '200px'
      }} />
                {progress < 100 && <progress value={progress} max='100' style={{
        width: '100%'
      }} />}
            </div>
        </div>;
};
export default HomeStagingUploadStep;