import React, { useCallback } from 'react';
import HomeStagingStepper from '@components/office/front-office/home/home-staging/dialog/HomeStagingStepper';
import HomeStagingUploadStep from '@components/office/front-office/home/home-staging/dialog/steps/upload/HomeStagingUploadStep';
import HomeStagingDefinitionStep from '@components/office/front-office/home/home-staging/dialog/steps/definition/HomeStagingDefinitionStep';
import HomeStagingGenerateStep from '@components/office/front-office/home/home-staging/dialog/steps/generate/HomeStagingGenerateStep';
import { CustomDialogContent } from '@components/bridge/bridge/DraggableHelperDialog.styles';
import { HomeStagingFormData } from '@components/office/front-office/home/home-staging/dialog/types/HomeStagingFormData';
import { HomeStagingStepConfiguration } from '@components/office/front-office/home/home-staging/dialog/types/HomeStagingStepConfiguration';
import HomeStagingOptionsStep from '@components/office/front-office/home/home-staging/dialog/steps/options/HomeStagingOptionsStep';
type Props = {
  stepperConfiguration: HomeStagingStepConfiguration[];
  isLoading: boolean;
  generationSrc: string;
  selectedStepNumber: number;
  formData: HomeStagingFormData;
  onChangeFormData: (key: string, value: string | any) => void;
};
const HomeStagingDialogContent = (props: Props) => {
  const {
    stepperConfiguration,
    selectedStepNumber,
    formData,
    isLoading,
    generationSrc,
    onChangeFormData
  } = props;
  const handleChangeFormData = useCallback((key: string, value: string | any) => {
    onChangeFormData(key, value);
  }, [onChangeFormData]);
  return <CustomDialogContent dividers style={{
    display: 'flex',
    flexDirection: 'column',
    height: '70vh',
    overflowY: 'auto'
  }}>
            <HomeStagingStepper stepperConfiguration={stepperConfiguration} selectedStepNumber={selectedStepNumber} />
            {selectedStepNumber === 0 && <HomeStagingUploadStep file={formData.file} onChange={event => handleChangeFormData('file', event)} />}
            {selectedStepNumber === 1 && <HomeStagingDefinitionStep room={formData.room} treatment={formData.treatment} style={formData.style} outRoom={formData.outRoom} onChange={(field, value) => handleChangeFormData(field, value)} />}
            {selectedStepNumber === 2 && <HomeStagingOptionsStep room={formData.room} style={formData.style} treatment={formData.treatment} blueSkyMode={formData.blueSkyMode} strength={formData.strength} onChange={(field, value) => handleChangeFormData(field, value)} />}
            {selectedStepNumber === 3 && <HomeStagingGenerateStep formData={formData} isLoading={isLoading} generationSrc={generationSrc} />}
        </CustomDialogContent>;
};
export default HomeStagingDialogContent;