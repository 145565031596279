import React from 'react';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { HomeStagingStepConfiguration } from '@components/office/front-office/home/home-staging/dialog/types/HomeStagingStepConfiguration';
import { useMediaQuery } from '@mui/material';
import useTheme from '@mui/system/useTheme';
type Props = {
  stepperConfiguration: HomeStagingStepConfiguration[];
  selectedStepNumber: number;
};
const HomeStagingStepper = (props: Props) => {
  const {
    stepperConfiguration,
    selectedStepNumber
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return <Stepper orientation={isMobile ? 'vertical' : 'horizontal'} activeStep={selectedStepNumber} style={{
    padding: '15px'
  }}>
            {stepperConfiguration.map(({
      index,
      title
    }) => <Step key={index}>
                    <StepLabel>
                        <div style={{
          display: 'flex',
          flexDirection: 'column'
        }}>
                            <span>{title}</span>
                        </div>
                    </StepLabel>
                </Step>)}
        </Stepper>;
};
export default HomeStagingStepper;