import React, { useState } from 'react';
import { Mousewheel, Navigation } from 'swiper';
import Fab from '@mui/material/Fab';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useMediaQuery } from '@mui/material';
import useTheme from '@mui/system/useTheme';
import { SkeletonContainer, StyledSwiper, SwipeButtonNext, SwipeButtonPrev } from '@components/bridge/bridge/slider/SwiperContainer.styles';
import { SwiperSlide } from 'swiper/react';
import { VisibilityContainer } from '@components/office/front-office/home/Category.styles';
interface SwiperContainerProps {
  children: React.ReactNode | React.ReactNode[];
  renderSkeleton: () => React.ReactNode;
  isLoading: boolean;
  totalCount: number;
  id: string;
}
const SwiperContainer = (props: SwiperContainerProps) => {
  const {
    children,
    id,
    isLoading,
    totalCount,
    renderSkeleton = () => null
  } = props;
  const itemsLength = (children as React.ReactNode[]).length;
  const isEmpty = itemsLength === 0;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMd = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isLg = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isXl = useMediaQuery(theme.breakpoints.between('xl', 1680));
  const isXXl = useMediaQuery(theme.breakpoints.up(1680));
  const [swiper, setSwiper] = useState();
  const prevRef = React.useRef();
  const nextRef = React.useRef();
  React.useEffect(() => {
    if (swiper) {
      (swiper as any).params.navigation.prevEl = prevRef.current;
      (swiper as any).params.navigation.nextEl = nextRef.current;
      (swiper as any).navigation.init();
      (swiper as any).navigation.update();
    }
  }, [swiper]);
  return <StyledSwiper modules={[Navigation, Mousewheel]} effect='slide'
  // cursor pointer on drag and drop
  grabCursor slidesPerView={isMobile ? 1 : window.innerWidth / (341 + 50)} updateOnWindowResize spaceBetween={isMobile ? 0 : isXXl ? 10 : isXl ? 30 : isLg ? 40 : isMd ? 20 : 4} onSwiper={(setSwiper as any)}
  // set up mouse wheel for scrolling
  freeMode direction='horizontal' mousewheel={{
    releaseOnEdges: true,
    sensitivity: 6,
    forceToAxis: true
  }} navigation={{
    prevEl: !isMobile && prevRef?.current,
    nextEl: !isMobile && nextRef?.current
  }} data-test-id={`swiperContainer-${id}`}>
            {itemsLength > 1 && <SwipeButtonPrev ref={prevRef} isMobile={isMobile}>
                    <Fab size='small' color='primary' aria-label='prev'>
                        <NavigateBeforeIcon />
                    </Fab>
                </SwipeButtonPrev>}

            {isLoading && <SkeletonContainer>
                    {[...Array(totalCount)].map((_item, i) => <SwiperSlide key={`skeleton-${i}`}>
                            <VisibilityContainer>{renderSkeleton()}</VisibilityContainer>
                        </SwiperSlide>)}
                </SkeletonContainer>}
            {!isLoading && !isEmpty && children}

            {itemsLength > 1 && <SwipeButtonNext ref={nextRef} isMobile={isMobile}>
                    <Fab size='small' color='primary' aria-label='next'>
                        <NavigateNextIcon />
                    </Fab>
                </SwipeButtonNext>}
        </StyledSwiper>;
};
export default SwiperContainer;