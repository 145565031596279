import React, { useCallback } from 'react';
import ImageToVideoCameraMoveEnum from '@components/office/front-office/home/image-to-video/dialog/enums/ImageToVideoCameraMoveEnum';
import ImageToVideoResolutionEnum from '@components/office/front-office/home/image-to-video/dialog/enums/ImageToVideoResolutionEnum';
import ImageToVideoCameraMoveChoice from '@components/office/front-office/home/image-to-video/dialog/steps/definitions/camera-move-choice/ImageToVideoCameraMoveChoice';
import ImageToVideoResolutionChoice from '@components/office/front-office/home/image-to-video/dialog/steps/definitions/resolution-choice/ImageToVideoResolutionChoice';
type Props = {
  selectedOptionCamera: ImageToVideoCameraMoveEnum;
  selectedOptionResolution: ImageToVideoResolutionEnum;
  onChange: (field: string, value: any) => void;
};
const ImageToVideoDefinitionStep = (props: Props) => {
  const {
    selectedOptionCamera,
    selectedOptionResolution,
    onChange
  } = props;
  const handleOptionChange = useCallback((field: string, value: any) => {
    onChange(field, value);
  }, [onChange]);
  return <div style={{
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    padding: '10px'
  }}>
            <ImageToVideoCameraMoveChoice value={selectedOptionCamera} onChange={(value: ImageToVideoCameraMoveEnum) => handleOptionChange('camera_move', value)} />
            <ImageToVideoResolutionChoice value={selectedOptionResolution} onChange={(value: ImageToVideoResolutionEnum) => handleOptionChange('resolution', value)} />
        </div>;
};
export default ImageToVideoDefinitionStep;