function queryCategories(id: string, privacyType: string, inWorkspace: string, inLibrary: string[]): string | null {
    return id
        ? `{
                homePayloadCategories:
                    user (id: "${id}") {
                        id
                        organization {
                            id
                            name
                            accessibleCategories (first: -1 viaLibraryToo: true sort: "displayRank:asc") {
                                totalCount
                                edges {
                                    node {
                                        id
                                        publicName
                                        description
                                        displayRank
                                        templatesOrganization: templates (
                                            private: ${privacyType}
                                            ${inWorkspace ? `inWorkspace: "${inWorkspace}"` : 'organizationRoot: true'}
                                        ) { totalCount }
                                        templatesLibrary: templates (
                                            private: ${privacyType}
                                            ${inLibrary ? `inLibrary: "${inLibrary.join(' | ')}"` : ''}
                                        ) { totalCount }
                                    }
                                }
                            }
                        }
                    }
            }`
        : null;
}

const queryCategoriesMock = () => 'FrontCategories';

const queryFrontCategoriesApi = process.env.NEXT_PUBLIC_MOCKS === 'true' ? queryCategoriesMock : queryCategories;

export default queryFrontCategoriesApi;
