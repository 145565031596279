import { Skeleton, Typography } from '@mui/material';
import { styled } from '@mui/system';
interface TitleSkeletonProps {
  customSize: number;
}
export const TitleSkeleton = styled(Skeleton)<TitleSkeletonProps>`
    display: inline-block;
    width: ${({
  customSize
}) => `${customSize}%`};
    height: 2.5rem;
    @media (max-width: 600px) {
        min-height: calc(16 / 9 * 8rem);
    }
`;
export const SwiperSkeleton = styled(Skeleton)`
    display: inline-block;
    width: 100%;
    min-height: calc(16 / 9 * 9.7rem);
    @media (max-width: 600px) {
        min-height: calc(16 / 9 * 8rem);
    }
`;
export const CategoryTitleContainer = styled('div')`
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-left: 2rem;
    user-select: none;
`;
export const CategoryContainer = styled('div')`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-x: hidden;
    // remove the marge on right from the parent page
    margin-right: -2rem;
    @supports (content-visibility: auto) {
        content-visibility: auto !important;
    }
    height: 22.7rem;
    overflow-y: hidden;
    @media (max-width: 600px) {
        gap: 0.5rem;
        height: 21.5rem;
        margin-left: -2rem;
    }
`;
export const CategoryTitle = styled(Typography)`
    display: inline-block;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    background: ${({
  theme
}) => `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.main} 50%, ${theme.palette.text.primary} 50%)`};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% 100%;
    background-position: 100%;
    transition: background-position 275ms ease;
    &:hover {
        background-position: 0 100%;
    }
    &::after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 2px;
        border-radius: 99px;
        bottom: 0;
        left: 0;
        background-color: ${({
  theme
}) => `${theme.palette.primary.main}`};
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
    }

    &:hover::after,
    &:focus::after {
        transform: scaleX(1);
        transform-origin: bottom left;
    }
`;
export const VisibilityContainer = styled('div')`
    height: 100%;
    @supports (content-visibility: auto) {
        padding: 1rem;
        content-visibility: auto !important;
    }
    padding-left: 1rem;
`;