import React, { useCallback, useState } from 'react';
import ImageToVideoDialog from '@components/office/front-office/home/image-to-video/ImageToVideoDialog';
import AnimatedButton from '@components/office/front-office/home/AnimatedButton';
import Image from 'next/image';
interface Props {
  isActive: boolean;
  generationsLimit: number;
  remainingGenerations: number;
  userEmail: string;
}
const ImageToVideoButton: React.FC<Props> = props => {
  const [openImageToVideo, setOpenImageToVideo] = useState<boolean>(false);
  const handleOpenImageToVideo = useCallback(() => setOpenImageToVideo(true), [setOpenImageToVideo]);
  const handleCloseImageToVideo = useCallback(() => setOpenImageToVideo(false), [setOpenImageToVideo]);
  const {
    isActive,
    generationsLimit,
    remainingGenerations,
    userEmail
  } = props;
  const tooltip = <div dangerouslySetInnerHTML={{
    __html: tr('image_to_video.tooltip', {
      limit: generationsLimit,
      remaining: remainingGenerations,
      mail: userEmail
    })
  }} />;
  return <>
            <ImageToVideoDialog remainingGenerations={remainingGenerations} open={openImageToVideo} onClose={handleCloseImageToVideo} />
            <AnimatedButton disabled={!isActive} handleClick={handleOpenImageToVideo} label={tr('image_to_video.title')} icon={<div style={{
      display: 'flex',
      alignItems: 'center'
    }}>
                        <Image src='/img/svg/imageToVideoIcon.svg' alt='imageToVideo' width='32' height='25' />{' '}
                    </div>} tooltip={tooltip} hideTooltipOnMobile />
        </>;
};
export default ImageToVideoButton;